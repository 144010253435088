/* eslint-disable no-useless-escape */
import { t } from "@lingui/macro";
import { Box, Link, Typography, makeStyles } from "@material-ui/core";
import Helmet from "Components/Helmet/Helmet";
import Loading from "Components/Loader/Loading";
import arrow from "assets/SubscriptionPage/come_back.svg";
import { setAdoptWineEntity } from "features/settings/settingsSlice";
import { useCustomHistory } from "hooks";
import PropTypes, { string } from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseButton from "../../Components/Buttons/BaseButton";
import SubscriptionForm from "../../Components/Forms/SubscriptionForm";
import { signIn } from "../../features/user/userSlice";
import { useCheckEmailAvailabilityMutation, useSignUpMutation } from "../../generated/graphql";
import theme from "../../globalTheme";
import { sanitizeName } from "../../helpers/helpers";
import FreeTrial from "./FreeTrial";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
    typoError: {
        fontSize: "14px",
        marginTop: "4px",
    },
    title: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: "25px",
        flexWrap: "wrap",
    },
    switchTypo: {
        cursor: "pointer",
        marginLeft: "4px",
        fontSize: "14px",
        color: theme.palette.primary.dark,
        alignSelf: "center",
        fontFamily: theme.typography.h6.fontFamily,
    },
    come_back_adopted_entity: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        width: "fit-content",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    creation_title: {
        [theme.breakpoints.up("xs")]: {
            textAlign: "center",
            gridColumn: "2 /span 10",
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
    submit_button: {
        gridColumn: "2 / span 10",
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
}));

export const ConnectedRegistrationContainer = (props) => {
    const styles = useStyles();
    const history = useCustomHistory();
    const [emailErrors, setEmailErrors] = useState(null);
    const [signUpMutation] = useSignUpMutation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const search = location?.search;
    const [titleValue, setTitleValue] = useState(null);
    const [firstNameValue, setFirstNameValue] = useState(null);
    const [lastNameValue, setLastNameValue] = useState(null);
    const [emailAddressValue, setEmailAddressValue] = useState(null);
    const [passwordValue, setPasswordValue] = useState(null);
    const [confirmationPasswordValue, setConfirmationPasswordValue] = useState(null);
    const [phoneNumberValue, setPhoneNumberValue] = useState(null);
    const [phoneNumberFlag, setPhoneNumberFlag] = useState("fr");
    const [newsletterValue, setNewsletterValue] = useState(null);
    const [serverError, setServerError] = useState(null);
    const currentUser = useSelector((state) => state?.user?.currentUser);
    const [freeTrial, setFreeTrial] = useState(false);
    const forcedFreeTrial = search && search === "?essai";
    const [checkEmailAvailability, { loading: checkLoading }] = useCheckEmailAvailabilityMutation();

    // PASSWORD CONSTRAINTS
    const lowerCaseRegex = /[a-z]/g;
    const upperCaseRegex = /[A-Z]/g;
    const symbolRegex = /[@$!%*?&]/g;
    const [focus, setFocus] = useState(false);
    const [lenConstraint, setLenConstraint] = useState(false);
    const [lowerCaseConstraint, setLowerCaseConstraint] = useState(false);
    const [upperCaseConstraint, setUpperCaseConstraint] = useState(false);
    const [symbolConstraint, setSymbolConstraint] = useState(false);

    useEffect(() => {
        setSymbolConstraint(!!passwordValue?.match(symbolRegex));
        setLenConstraint(passwordValue?.length >= 8);
        setLowerCaseConstraint(!!passwordValue?.match(lowerCaseRegex));
        setUpperCaseConstraint(!!passwordValue?.match(upperCaseRegex));
    }, [passwordValue]);

    useEffect(() => {
        if (currentUser && currentUser.status !== "PAUSED") {
            history.replace("/subscription/");
        }
    }, [currentUser]);

    useEffect(() => {
        if (location?.state?.freeTrial || forcedFreeTrial) {
            setFreeTrial(true);
        }
    }, [location, search]);

    const onSubmit = async () => {
        setServerError(null);
        try {
            const resEmail = await checkEmailAvailability({
                variables: { email: emailAddressValue },
            });
            const isAvailable = resEmail.data?.CheckEmailAvailability;

            if (isAvailable) {
                setLoading(true);
                await signUpMutation({
                    variables: {
                        input: {
                            title: titleValue,
                            firstName: sanitizeName(firstNameValue),
                            lastName: sanitizeName(lastNameValue),
                            email: emailAddressValue,
                            password: passwordValue,
                            telephoneCountry: phoneNumberFlag,
                            telephone: phoneNumberValue,
                            newsletter: newsletterValue,
                            freeTrial,
                        },
                    },
                });
                dispatch(
                    signIn({
                        username: emailAddressValue,
                        password: passwordValue,
                    })
                );
            } else {
                setEmailErrors("Email déjà utilisé");
            }
        } catch (err) {
            setLoading(false);
            setServerError("Erreur lors de l'inscription veuillez réessayer");
            // eslint-disable-next-line no-console
            console.error(err);
        }
    };

    const passwordError = () => {
        if (passwordValue) {
            return !symbolConstraint || !lenConstraint || !lowerCaseConstraint || !upperCaseConstraint;
        }
        return false;
    };

    const confirmationPasswordError = () => {
        if (confirmationPasswordValue) {
            return !(confirmationPasswordValue === passwordValue);
        }
    };

    // EMAIL CONSTRAINTS
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const emailError = !emailAddressValue?.match(emailRegex);

    const getEmailErrors = () => {
        if (emailAddressValue && emailError) {
            return "Le champ n'est pas valide";
        }
        if (emailErrors) {
            return "Email déjà utilisé";
        }
    };

    const firstNameErrors = firstNameValue?.length < 1 || firstNameValue?.length > 30;
    const lastNameErrors = lastNameValue && (lastNameValue?.length < 2 || lastNameValue?.length > 30);
    const phoneNumberErrors = phoneNumberValue && (phoneNumberValue?.length !== 14 || phoneNumberValue[0] !== "0");

    if (loading) {
        return <Loading loading={loading} />;
    }

    return (
        <>
            <Helmet
                title={t`Inscription`}
                content="Créez votre compte gratuitement sur Bordeaux et ses Vins et bénéficiez d'un espace sécurisé pour gérer vos informations. Grâce à notre plateforme, créez et diffusez facilement vos fiches techniques pour faire connaître vos vins et votre savoir-faire. Rejoignez la communauté des acteurs passionnés du vignoble bordelais et valorisez votre patrimoine viticole."
            />
            {!props.adoptWineEntity && (
                <FreeTrial onChange={() => setFreeTrial(!freeTrial)} checked={freeTrial} hideToggle={forcedFreeTrial} />
            )}
            <Box className={styles.creation_title}>
                <Typography
                    variant="h2"
                    style={{
                        marginBottom: theme.spacing(1),
                        zIndex: 5,
                        position: "relative",
                    }}
                >
                    {props.adoptWineEntity
                        ? `${props.adoptWineEntity?.estateName} est votre propriété ? Reprenez le contrôle de sa visibilité !`
                        : "Créez votre compte Féret"}
                </Typography>
                <Typography variant="h5">
                    {props.adoptWineEntity
                        ? "Créez votre compte Féret."
                        : "Il vous permettra de gérer, de publier vos informations dans le guide historique Féret et de profiter de nombreuses fonctionnalités."}
                </Typography>
            </Box>
            {/* MANUAL SUBSCRIPTION SECTION */}
            {serverError && (
                <Typography
                    color="error"
                    align="center"
                    className={styles.typoError}
                    style={{ marginBottom: theme.spacing(3) }}
                >
                    {serverError}
                </Typography>
            )}
            <SubscriptionForm
                title={{
                    value: titleValue,
                    onChange: (e) => {
                        setTitleValue(e);
                    },
                }}
                lastName={{
                    value: lastNameValue,
                    onChange: (e) => {
                        setLastNameValue(e.target.value);
                    },
                    correctValue: lastNameValue && !lastNameErrors,
                    error: lastNameErrors,
                }}
                firstName={{
                    value: firstNameValue,
                    onChange: (e) => setFirstNameValue(e.target.value),
                    correctValue: firstNameValue && !firstNameErrors,
                    error: firstNameErrors,
                }}
                emailAddress={{
                    value: emailAddressValue,
                    onChange: (e) => setEmailAddressValue(e.target.value),
                    error: emailAddressValue && (emailError || emailErrors),
                    clue: getEmailErrors(),
                    correctValue: emailAddressValue && !emailError && !emailErrors,
                    falseValue: emailError || emailErrors,
                    onFocus: () => setEmailErrors(null),
                }}
                password={{
                    value: passwordValue,
                    onChange: (e) => setPasswordValue(e.target.value),
                    error: passwordError(),
                    focus,
                    setFocus,
                    constraints: {
                        lenConstraint,
                        setLenConstraint,
                        upperCaseConstraint,
                        setUpperCaseConstraint,
                        lowerCaseConstraint,
                        setLowerCaseConstraint,
                        symbolConstraint,
                        setSymbolConstraint,
                    },
                }}
                confirmationPassword={{
                    value: confirmationPasswordValue,
                    onChange: (e) => setConfirmationPasswordValue(e.target.value),
                    error: confirmationPasswordError(),
                    correctValue: confirmationPasswordValue && confirmationPasswordValue === passwordValue,
                    helperText: confirmationPasswordError() && "Le mot de passe doit être identique",
                }}
                newsletter={{
                    value: newsletterValue,
                    onChange: (e) => setNewsletterValue(e.target.checked),
                }}
                phoneNumber={{
                    value: phoneNumberValue,
                    onChange: (e) => setPhoneNumberValue(e),
                    error: phoneNumberErrors,
                    correctValue: phoneNumberValue && !phoneNumberErrors,
                }}
                phoneNumberFlag={{
                    value: phoneNumberFlag,
                    onChange: (e) => setPhoneNumberFlag(e),
                }}
            />

            <Box className={styles.submit_button}>
                <BaseButton
                    fullWidth
                    type="submit"
                    disabled={
                        !passwordValue ||
                        passwordError() ||
                        firstNameErrors ||
                        lastNameErrors ||
                        phoneNumberErrors ||
                        !confirmationPasswordValue ||
                        confirmationPasswordError() ||
                        !firstNameValue ||
                        !lastNameValue ||
                        !emailAddressValue ||
                        emailError ||
                        !phoneNumberValue ||
                        checkLoading
                    }
                    color="primary"
                    variant="contained"
                    onClick={() => onSubmit()}
                >
                    S&apos;inscrire
                </BaseButton>

                {props.adoptWineEntity && (
                    <Box
                        className={styles.come_back_adopted_entity}
                        onClick={async () => {
                            dispatch(setAdoptWineEntity(null));
                            history.goBack();
                        }}
                    >
                        <img src={arrow} />

                        <Typography
                            variant="h6"
                            style={{
                                fontFamily: "Mansalva",
                                color: theme.palette.primary.light,
                                marginLeft: "0.5rem",
                            }}
                        >
                            Retour, je ne souhaite plus cette propriété
                        </Typography>
                    </Box>
                )}
                {props.textSignIn ? (
                    <Box className={styles.title}>
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: "14px",
                                color: theme.palette.primary.dark,
                            }}
                        >
                            Vous avez déjà un compte ?{" "}
                        </Typography>

                        <Link
                            onClick={() => history.push("/bienvenue/connexion")}
                            underline="always"
                            className={styles.switchTypo}
                        >
                            Connectez-vous
                        </Link>
                    </Box>
                ) : null}
            </Box>
        </>
    );
};
ConnectedRegistrationContainer.propTypes = {
    textSignIn: PropTypes.bool,
    adoptWineEntity: string,
};
