import { Box, makeStyles, Typography } from "@material-ui/core";
import { bool, func, string } from "prop-types";
import { useState } from "react";

import barriquePremium from "assets/icons/custom/barrique_premium.svg";
import bouteillesEssentiel from "assets/icons/custom/bouteilles_essentiel.svg";
import initial from "assets/icons/custom/initial.svg";
import wineGlass from "assets/icons/custom/verre_basique.svg";
import SubscriptionsType from "Components/FrontBESV/SubscriptionsType";
import ControlledSwitch from "Components/Switch/ControlledSwitch";
import { SUBSCRIPTION_TYPES } from "config/constants";
import theme from "globalTheme";
import { useWindowDimensions } from "helpers/helpers";
import ComparisonTableHeaderElement from "SubscriptionTunnel/Components/ComparisonTable/ComparisonTableHeaderElement";

import Modal from "../../Containers/Modal";
import { allPrices, PACKS } from "../constants";
import { STARTUP_HT } from "Dashboard/Pages/ServiceDashboard/constants";

const useStyles = makeStyles(() => ({
    contentCard: {
        overflow: "scroll",
    },
    bold: {
        fontWeight: "900",
    },
    withPlus: {
        fontWeight: "900",
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.dark,
        width: 120,
        margin: "0 auto",
        borderRadius: 3,
    },
    cell: {
        ...theme.typography.h5,
        color: theme.palette.primary.dark,
        textAlign: "center ",
        padding: "0.5rem 0",
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
        [theme.breakpoints.up("xs")]: {
            width: "250px",
        },
        [theme.breakpoints.up("md")]: {
            width: "222px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "350px",
        },
    },
    cellFix: {
        [theme.breakpoints.up("xs")]: {
            width: "250px",
        },
        [theme.breakpoints.up("lg")]: {
            width: "222px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "inherit",
        },
    },
    switch_container: {
        [theme.breakpoints.up("sm")]: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            marginBottom: "-50px",
            gap: "1rem",
        },
        [theme.breakpoints.up("xl")]: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            marginBottom: "0",
        },
    },
}));

const ComparisonTablePopinContainer = (props) => {
    const styles = useStyles();
    const [expandItem, setExpandItem] = useState("");
    const [initialPack, setInitialPack] = useState(false);
    const [essentialPack, setEssentialPack] = useState(false);
    const { width } = useWindowDimensions();

    const onSelectPack = (pack) => {
        props.handleClose();
        props.subscriptionDispatch({
            type: "setValue",
            payload: { fieldName: "selectedPack", fieldValue: pack },
        });
    };

    let packs = [
        {
            ...PACKS.basic,
            path: wineGlass,
            onClick: () => onSelectPack(PACKS.basic.id),
            totalMonthly: " ",
        },
        {
            ...PACKS.initial,
            path: initial,
            onClick: () => onSelectPack(PACKS.initial.id),
            price: props.switchCheck ? allPrices?.initial?.ht : allPrices?.initial?.monthly,
            totalMonthly: allPrices?.initial.totalMonthly,
            setPack: (enable) => setInitialPack(enable),
            pack: initialPack,
            pricePack: props.switchCheck ? allPrices?.initial?.htPack : allPrices?.initial?.monthlyPack,
        },
        {
            ...PACKS.essential,
            path: bouteillesEssentiel,
            onClick: () => onSelectPack(PACKS.essential.id),
            price: props.switchCheck ? allPrices?.essential?.ht : allPrices?.essential?.monthly,
            totalMonthly: allPrices?.essential.totalMonthly,
            setPack: (enable) => setEssentialPack(enable),
            pack: essentialPack,
            pricePack: props.switchCheck ? allPrices?.essential?.htPack : allPrices?.essential?.monthlyPack,
        },
        {
            ...PACKS.premium,
            path: barriquePremium,
            onClick: () => onSelectPack(PACKS.premium.id),
            price: props.switchCheck ? allPrices?.premium?.ht : allPrices?.premium?.monthly,
            totalMonthly: allPrices?.premium.totalMonthly,
        },
    ];

    return (
        <Modal
            open={props.modalOpen}
            onClose={props.handleClose}
            actionNeeded={false}
            contentCard={styles.contentCard}
            cardStyle={{ maxWidth: "80rem" }}
        >
            <SubscriptionsType
                setExpandItem={(id) => setExpandItem(id)}
                collapse={props.collapse}
                expandItem={expandItem}
                backgroundColor={theme.palette.common.white}
                switchCheck={props.switchCheck}
                cellClassName={styles.cell}
                cellFixClassName={styles.cellFix}
                arrowScrollWidth={1920}
                data={[
                    {
                        title: props.disabledSwitch ? (
                            <div style={{ width: "200px" }} />
                        ) : (
                            <Box className={styles.switch_container}>
                                {width < 1920 && "Facturation"}
                                <ControlledSwitch
                                    switchCheck={props.switchCheck}
                                    handleSwitchChange={(event) => {
                                        props.handleSwitchChange(!props.switchCheck);
                                    }}
                                    rightLabel={width >= 1920 ? "Facturation annuelle" : "annuelle"}
                                    leftLabel={width >= 1920 ? "Facturation mensuelle" : "mensuelle"}
                                    rightLabelExtra={
                                        <Typography
                                            style={{
                                                opacity: props.switchCheck ? "1" : "0.5",
                                                borderRadius: "4px",
                                                padding: "2px 4px",
                                                fontSize: "9px",
                                                color: theme.palette.primary.main,
                                                backgroundColor: theme.palette.secondary.main,
                                            }}
                                        >
                                            Économisez jusqu&apos;à 14 %
                                        </Typography>
                                    }
                                />
                            </Box>
                        ),
                        tooltip: "",
                        cells: packs.map((e) => {
                            return {
                                title: (
                                    <ComparisonTableHeaderElement
                                        key={e.title}
                                        switchCheck={props.switchCheck}
                                        discountPrice={props.switchCheck && props.discountPrice}
                                        disabledHeader={props.disabledHeader}
                                        price={e.pack ? e.pricePack : e.price}
                                        packPlus={Boolean(e.setPack)}
                                        path={e.path}
                                        setPack={(enable) => e.setPack(enable)}
                                        pack={e.pack}
                                        title={e.labelComparisonTable}
                                        period={
                                            e.id === SUBSCRIPTION_TYPES.basic
                                                ? e.period
                                                : props.switchCheck
                                                ? "/an"
                                                : "/mois"
                                        }
                                        // totalMonthly={!props.switchCheck ? e?.totalMonthly : null}
                                        buttonLabel="Choisir"
                                        onClick={e.onClick}
                                        disabled={e.disabled}
                                        hideCTA={props?.hideCTA}
                                    />
                                ),
                            };
                        }),
                    },
                    {
                        id: "paperEdition",
                        title: (
                            <Typography variant="h6" key="">
                                Parution dans <i>Bordeaux et ses Vins</i> XXe édition (papier)
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <>
                                <Box>
                                    Depuis sa première édition en 1850, <i>Bordeaux et ses Vins</i> est la référence de
                                    la Place de Bordeaux pour les professionnels et les amateurs avertis. Référence pour
                                    les professionnels, au sens large du terme, tous les acteurs de la filière vins, des
                                    acheteurs de la grande distribution aux cavistes, en passant par les restaurateurs,
                                    les sommeliers, les experts et les courtiers, qui sont depuis tout temps les
                                    premiers lecteurs de <i>Bordeaux et ses Vins</i>.
                                </Box>
                                <br />
                                <Box>
                                    C’est aussi une référence pour les importateurs étrangers, la distribution de{" "}
                                    <i>Bordeaux et ses Vins</i> est par essence internationale, que ce soit en langue
                                    française ou en langue anglaise les importateurs du monde entier connaissent notre
                                    maison et sont nos lecteurs.
                                </Box>
                                <br />
                                <Box>
                                    Quels que soient votre volume de production, votre mode de distribution,{" "}
                                    <i>Bordeaux et ses Vins</i> demeure pour tous un outil de référence précieux et
                                    incontournable. Soyez présent dans sa très attendue XX<sup>e</sup> édition !
                                </Box>
                            </>
                        ),
                        cells: [
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Ligne seulement
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        - 20%
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        - 20%
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        - 50%
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "estateDescription",
                        title: (
                            <Typography variant="h6" key="">
                                Présentation de la propriété
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <>
                                <Box>
                                    Dans <i>Bordeaux et ses Vins</i> en ligne, comme sous son format imprimé historique,
                                    votre propriété est mise en valeur : vous bénéficiez de la notoriété internationale
                                    de l&apos;encyclopédie la plus complète des châteaux et vins de Bordeaux,
                                    régulièrement enrichie depuis sa première édition en 1850.
                                </Box>
                                <br />
                                <Box>
                                    Racontez votre histoire et illustrez-la avec des photos de votre choix. Vous pouvez
                                    déclarer les labels et les certifications obtenus par votre propriété, nous les
                                    certifierons. Ils seront mis en avant sur votre page publique. Présentez vos
                                    installations, votre vignoble et votre terroir. Décrivez vos surfaces de production
                                    automatiquement grâce à l&apos;import de votre CVI et bénéficiez de la certification
                                    du Badge Féret, ou saisissez-les manuellement.
                                </Box>
                            </>
                        ),
                        cells: [
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Limitée
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Complète
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Complète
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Complète
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "teamDescription",
                        title: (
                            <Typography variant="h6" key="">
                                Présentation de l’équipe
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Mettez votre équipe à l&apos;honneur et présentez les femmes et les hommes qui font la
                                réussite de votre activité. C&apos;est non seulement l&apos;occasion parfaite de les
                                valoriser, mais c&apos;est aussi une belle façon d&apos;humaniser votre production, et
                                c&apos;est ce dont les consommateurs sont de plus en plus friands.
                            </Box>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: initialPack ? (
                                    <Typography variant="h6" className={styles.withPlus} key="">
                                        6 personnes
                                    </Typography>
                                ) : (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        2 personnes
                                    </Typography>
                                ),
                            },
                            {
                                title: essentialPack ? (
                                    <Typography variant="h6" className={styles.withPlus} key="">
                                        6 personnes
                                    </Typography>
                                ) : (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        2 personnes
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Illimitée
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "winesDescription",
                        title: (
                            <Typography variant="h6" key="">
                                Présentation des vins
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Renseignez les experts et les professionnels du vin à la recherche de données
                                pertinentes et authentiques, et offrez à vos clients les informations qu&apos;ils
                                n&apos;ont jamais osé vous demander. Mettez en avant toutes les caractéristiques
                                techniques de votre choix : modes de culture, qualités organoleptiques, types de
                                commercialisation, certifications, dates de vendange, stades phénologiques... et bien
                                plus encore.
                            </Box>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: initialPack ? (
                                    <Typography variant="h6" className={styles.withPlus} key="">
                                        8 vins
                                    </Typography>
                                ) : (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        4 vins
                                    </Typography>
                                ),
                            },
                            {
                                title: essentialPack ? (
                                    <Typography variant="h6" className={styles.withPlus} key="">
                                        8 vins
                                    </Typography>
                                ) : (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        4 vins
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Illimitée
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "vintagesDescription",
                        title: (
                            <Typography variant="h6" key="">
                                Présentation des millésimes
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <>
                                <Box>
                                    Présenter à vos clients la finesse et l&apos;évolution de chaque cru, les
                                    transportant à travers le temps et l&apos;âme de votre domaine. Grâce à Bordeaux et
                                    ses Vins, vous pourrez mettre en lumière les caractéristiques subtiles de chaque
                                    millésime, créant ainsi une expérience sensorielle inoubliable.
                                </Box>
                                <br />
                                <Box>
                                    En offrant à vos visiteurs la possibilité de découvrir l&apos;authenticité de chaque
                                    millésime, vous renforcerez la confiance de vos clients et vous distinguerez dans
                                    l&apos;univers des vins d&apos;exception. De plus, notre solution intuitive vous
                                    permettra de mettre à jour facilement les informations sur vos millésimes, assurant
                                    ainsi une présentation précise et à jour.
                                </Box>
                            </>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: initialPack ? (
                                    <Typography variant="h6" className={styles.withPlus} key="">
                                        48 millésimes
                                    </Typography>
                                ) : (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        12 millésimes
                                    </Typography>
                                ),
                            },
                            {
                                title: essentialPack ? (
                                    <Typography variant="h6" className={styles.withPlus} key="">
                                        48 millésimes
                                    </Typography>
                                ) : (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        12 millésimes
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Illimitée
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "activityDescription",
                        title: (
                            <Typography variant="h6" key="">
                                Présentation des activités œnotouristiques
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Profitez de la dynamique de <i>Bordeaux et ses Vins</i> pour valoriser vos activités
                                œnotouristiques. Indiquez la nature détaillée de toutes vos activités, et ajoutez-y une
                                description exhaustive, accompagnée de photos qui susciteront l&apos;envie chez vos
                                visiteurs. Évoquez les différentes modalités de réservation, d&apos;accessibilité ainsi
                                que les tarifs.
                            </Box>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: initialPack ? (
                                    <Typography variant="h6" className={styles.withPlus} key="">
                                        6 activités
                                    </Typography>
                                ) : (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        3 activités
                                    </Typography>
                                ),
                            },
                            {
                                title: essentialPack ? (
                                    <Typography variant="h6" className={styles.withPlus} key="">
                                        6 activités
                                    </Typography>
                                ) : (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        3 activités
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Illimitée
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "rewardAuto",
                        title: (
                            <Typography variant="h6" key="">
                                Intégration automatique des médailles
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Grâce à la version en ligne de <i>Bordeaux et ses Vins</i>, retrouvez directement dans
                                votre tableau de bord vos médailles obtenues, automatiquement importées par Féret
                                provenant des organismes officiels de concours, vous n&apos;avez rien à faire !
                            </Box>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: initialPack ? "truePack" : "false",
                            },
                            {
                                title: "true",
                            },
                            {
                                title: "true",
                            },
                        ],
                    },
                    {
                        id: "scoresAuto",
                        title: (
                            <Typography variant="h6" key="">
                                Intégration automatique des notes par Féret*
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <>
                                <Box>
                                    Retrouvez l&apos;intégralité des notes décernées à vos vins par des dégustateurs
                                    professionnels nationaux et étrangers, ajoutées automatiquement dans votre tableau
                                    de bord. Vous maîtrisez également la visibilité de chaque note sur votre page
                                    publique.
                                </Box>
                                <br />
                                <Box>
                                    Optimisez votre temps et diffusez des informations certifiées grâce à
                                    l&apos;automatisation et à la fiabilité des données obtenues par les Éditions Féret.
                                </Box>
                            </>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: "false",
                            },
                            {
                                title: "false",
                            },
                            {
                                title: "true",
                            },
                        ],
                    },
                    {
                        id: "scoresDeclaration",
                        title: (
                            <Typography variant="h6" key="">
                                Déclaration de notes
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <>
                                <Box>
                                    Plus personne ne connaît vos vins mieux que vous. C&apos;est pourquoi nous vous
                                    donnons le pouvoir de partager les notes de dégustation de vos propres vins. Vous
                                    pouvez désormais déclarer fièrement les scores, les éloges et les reconnaissances
                                    que vos vins ont reçus, renforçant ainsi votre crédibilité et votre réputation
                                    auprès des connaisseurs et des amateurs de vin.
                                </Box>
                                <br />
                                <Box>
                                    Chez Féret, nous valorisons l&apos;exactitude et l&apos;intégrité des informations.
                                    C&apos;est pourquoi nos experts vérifient régulièrement les scores et les notes de
                                    dégustation que vous ajoutez à vos fiches techniques. Cette démarche garantit que
                                    les informations que vous partagez sont fiables et conformes aux normes les plus
                                    strictes de l&apos;industrie.
                                </Box>
                            </>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: initialPack ? "truePack" : "false",
                            },
                            {
                                title: "true",
                            },
                            {
                                title: "true",
                            },
                        ],
                    },
                    {
                        id: "qrcodeNutriInfos",
                        title: (
                            <Typography variant="h6" key="">
                                QR Code déclaration nutritionnelle
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Soyez prêt pour la nouvelle réglementation d&apos;étiquetage des vins qui entrera en
                                vigueur dès le 8 décembre 2023. Profitez de notre solution pour dématérialiser la
                                composition et la déclaration nutritionnelle de chacun de vos millésimes grâce à des QR
                                Codes. Comment faire ? Rien de plus facile, laissez-vous guider : renseignez vos
                                informations, enregistrez-les et obtenez immédiatement votre QR Code !
                            </Box>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: "true",
                            },
                            {
                                title: "true",
                            },
                            {
                                title: "true",
                            },
                        ],
                    },
                    {
                        id: "estateStoryFromFeret",
                        title: (
                            <Typography variant="h6" key="">
                                Accès à l&apos;historique Féret de la propriété
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Féret est incontestablement la mémoire de Bordeaux depuis plus de 200 ans avec son guide{" "}
                                <i>Bordeaux et ses Vins</i>, l&apos;encyclopédie la plus complète au monde sur le
                                vignoble bordelais. Nous retraçons pour vous l&apos;histoire de votre propriété,
                                profitez de nos archives et accédez facilement aux différentes apparitions de votre
                                propriété dans le Guide.
                            </Box>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: "true",
                            },
                            {
                                title: "true",
                            },
                            {
                                title: "true",
                            },
                        ],
                    },
                    {
                        id: "ftModels",
                        title: (
                            <Typography variant="h6" key="">
                                Modèles disponibles pour la création des fiches techniques
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Accédez à notre bibliothèque de modèles adaptés à chaque besoin et à chaque
                                destinataire. À partir de la donnée que vous avez saisie une seule fois dans votre
                                tableau de bord, nous déclinerons pour vous de nombreuses fiches techniques sous divers
                                formats, pour contenter vos visiteurs à la propriété, vos cavistes, vos négociants...
                                Tous n&apos;ont pas les mêmes besoins, et vous n&apos;aurez pas à saisir les
                                informations pour chacun, c&apos;est automatique !
                            </Box>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: "false",
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        3 modèles
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Tous modèles
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "ftCreator",
                        title: (
                            <Typography variant="h6" key="">
                                Accès à l’éditeur de fiches techniques Féret
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Nous avons confectionné pour vous un outil graphique de génération de fiche technique
                                avec lequel vous pouvez créer votre fiche technique à partir d&apos;une feuille blanche
                                ou d&apos;un modèle. Imaginez vous-même son design à partir des données que vous aurez
                                déjà renseignées dans votre tableau de bord. Très intuitif, notre éditeur vous fera
                                gagner un temps précieux grâce à son système de glisser-déposer.
                            </Box>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: "false",
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Limité
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Complet
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "sendFt",
                        title: (
                            <Typography variant="h6" key="">
                                Envoi des fiches techniques via <i>Bordeaux et ses Vins</i>
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <>
                                <Box>
                                    Ajoutez un à un vos contacts dans votre carnet d&apos;adresses Féret ou importez-les
                                    en masse. Segmentez-les en listes et abonnez-les à vos fiches techniques !
                                </Box>
                                <Box>
                                    Une fois une liste abonnée à une ou plusieurs fiches techniques, les contacts
                                    qu&apos;elle rassemble auront toujours accès à la version actuelle de la fiche.
                                    Chacun pourra la consulter et la télécharger dans la langue disponible de leur
                                    choix.
                                </Box>
                                <br />
                                <Box>
                                    Envoyez vos fiches techniques via <i>Bordeaux et ses Vins</i> et accédez aux
                                    statistiques de consultation et de téléchargement par langue et par destinataire.
                                </Box>
                                <br />
                                <Box>
                                    Vous avez modifié des informations contenues dans votre fiche technique ? Votre vin
                                    a reçu une médaille, une note ? Tous les abonnés de votre fiche technique en seront
                                    informés de manière automatique, vous n’avez rien à faire !
                                </Box>
                                <br />
                                <Box>
                                    Gardez vos prospects et vos clients informés de toutes vos nouveautés, c&apos;est
                                    automatique !
                                </Box>
                            </>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: "false",
                            },
                            {
                                title: essentialPack ? (
                                    <Typography variant="h6" className={styles.withPlus} key="">
                                        2000 contacts
                                    </Typography>
                                ) : (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        250 contacts max.
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Illimités
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "ftpDiffusion",
                        title: (
                            <Typography variant="h6" key="">
                                Diffusion des fiches techniques via des flux FTP
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Diffusez vos fiches techniques via des flux FTP et bénéficiez de la garantie
                                d&apos;avoir toujours vos fiches techniques Féret à jour sur votre propre site ou où
                                vous le souhaitez ! Vous créez une fois vos fiches techniques dans votre Espace Féret,
                                et elles sont à vous pour une utilisation illimitée. De plus, si l&apos;un de vos vins
                                remporte une médaille ou obtient une note de dégustation, cela sera automatiquement
                                ajoutée à la fiche technique, et la synchronisation du flux FTP sera instantanée.
                                Résultat : la dernière version de la fiche technique que vous avez créée sur Féret est
                                immédiatement accessible à vos clients sur votre site, pour une expérience toujours à
                                jour et de qualité.
                            </Box>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: "false",
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (20 €/mois)
                                    </Typography>
                                ),
                            },
                            {
                                title: "true",
                            },
                        ],
                    },
                    {
                        id: "dowloadFt",
                        title: (
                            <Typography variant="h6" key="">
                                Téléchargement des fiches techniques en PDF{" "}
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Les fiches techniques que vous avez conçues sont disponibles au format PDF. Vous pouvez
                                les télécharger pour l&apos;usage que vous souhaitez, de manière illimitée.
                            </Box>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: "false",
                            },
                            {
                                title: "true",
                            },
                            {
                                title: "true",
                            },
                        ],
                    },
                    {
                        id: "enrichDatas",
                        title: (
                            <Box>
                                <Typography variant="h6" key="">
                                    Alimentation des partenaires
                                </Typography>
                                <Typography
                                    variant="subtitle"
                                    key=""
                                    style={{ color: theme.palette.secondary.dark, fontSize: 10 }}
                                >
                                    (sites e-commerces, négociants etc...)
                                </Typography>
                            </Box>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Enrichissez automatiquement vos informations vers les distributeurs (site e-commerce,
                                négociants, etc...) partenaires de Féret. Nous les alimentons, pour vous, avec les
                                informations que vous aurez déjà saisies dans votre tableau de bord. Vous n&apos;avez
                                rien à faire !
                            </Box>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Manuelle
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Automatique
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" className={styles.bold} key="">
                                        Automatique
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "badgeFeret",
                        title: (
                            <Typography variant="h6" key="">
                                Accès à la certification : le Badge Féret
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <>
                                <Box>
                                    Rassurez vos clients quant à la fiabilité de vos informations en obtenant le Badge
                                    Féret, certification officiellement reconnue et approuvée. Les informations
                                    présentes sur le site bordeaux.feret.com, mais aussi les données affichées sur vos
                                    fiches techniques seront certifiées par Féret.
                                </Box>
                                <br />
                                <Box>
                                    1. Toute déclaration de certification ou de label (bio, HVE, etc) devra être
                                    accompagnée d&apos;un document justificatif qui sera contrôlé par les experts Féret
                                    avant d&apos;être publié comme information certifiée.
                                </Box>
                                <br />
                                <Box>
                                    2. Féret importe des données non modifiables provenant de sources officielles :
                                    classements, notes, médailles.
                                </Box>
                                <br />
                                <Box>
                                    C&apos;est l&apos;agrégation de ces deux sources de données vérifiées qui permet
                                    l&apos;attribution du Badge Féret.
                                </Box>
                                <br />
                                <Box>
                                    Notre procédure de certification est en cours d&apos;obtention du label `&quot;Data
                                    Integrity`&quot; délivré par Bureau Veritas, organisme de contrôle et de
                                    certification reconnu dans le monde entier.
                                </Box>
                            </>
                        ),
                        cells: [
                            {
                                title: "true",
                            },
                            {
                                title: "true",
                            },
                            {
                                title: "true",
                            },
                            {
                                title: "true",
                            },
                        ],
                    },
                    {
                        id: "translate",
                        title: (
                            <Typography variant="h6" key="">
                                Traduction en 8 langues
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <>
                                <u>Traduction manuelle</u>
                                <Box>
                                    Par défaut, tous les contenus que vous sélectionnez dans votre espace Féret sont
                                    traduits en 8 langues. Après avoir saisi vos informations dans votre tableau de
                                    bord, vous pouvez faire des demandes de traduction. Huit langues sont disponibles.
                                </Box>
                                <Box>
                                    Notre équipe de traduction dédiée est spécialisée dans le monde vitivinicole. Elle
                                    vous fournira des traductions fiables et de qualité qui contribueront au
                                    développement de votre activité et de votre image à l&apos;international.
                                </Box>
                                <br />
                                <u>Traduction intégrée</u>
                                <Box>
                                    Nous avons fait le choix d’intégrer dans nos serveurs une architecture de traduction
                                    de très haut niveau, basée sur les réseaux neuronaux et enrichie par un glossaire
                                    spécialisé conçu par des experts du monde du vin. Les moteurs de traduction sont «
                                    entraînés » grâce à des modèles mathématiques permettant de traiter des phrases dans
                                    leur ensemble en fonction du concept ou de l&apos;idée qu’elles présentent et non
                                    mot par mot.
                                </Box>
                                <br />
                                <Box>
                                    Toutes les données descriptives dites &quot;techniques&quot; que vous n&apos;aurez
                                    pas directement saisies sont automatiquement disponibles en 8 langues.
                                </Box>
                            </>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: "true",
                            },
                            {
                                title: "true",
                            },
                            {
                                title: "true",
                            },
                        ],
                    },
                    {
                        id: "translateMore",
                        title: (
                            <Typography variant="h6" key="">
                                Traduction supplémentaire
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Les traductions supplémentaires concernent les informations non &quot;techniques&quot;,
                                celles que vous aurez saisies directement dans votre Espace Féret.
                            </Box>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (dès <b>0,16 €/mot</b>)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (dès <b>0,16 €/mot</b>)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        -20% <b>Option</b> (dès <b>0,13 €/mot</b>)
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "degustation",
                        title: (
                            <Typography variant="h6" key="">
                                Dégustation à la demande
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <>
                                <Box>
                                    Laissez Féret s&apos;occuper de tout lorsque vous souhaitez faire déguster un
                                    millésime particulier à l&apos;un de nos précieux journalistes partenaires ! Avec
                                    notre solution complète, vous pouvez facilement mettre en avant votre millésime
                                    sélectionné, tandis que nous nous occupons de toutes les étapes nécessaires.
                                </Box>
                                <br />
                                <Box>
                                    Nous nous engageons à simplifier votre processus de présentation en automatisant
                                    l&apos;ajout des notes de dégustation à vos fiches techniques. Cela signifie que
                                    vous pouvez vous concentrer sur la création de vins exceptionnels, tandis que nous
                                    nous assurons que vos ventes sont stimulées et que votre réputation est renforcée.
                                </Box>
                            </>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: initialPack ? (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (160€/dégus. )
                                    </Typography>
                                ) : (
                                    "false"
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (160€/dégus. )
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (160€/dégus. )
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "standalone",
                        title: (
                            <Box>
                                <Typography variant="h6" key="">
                                    Accès à la page propriété via url personnalisée
                                </Typography>
                                <Typography
                                    variant="subtitle"
                                    key=""
                                    style={{ color: theme.palette.secondary.dark, fontSize: 10 }}
                                >
                                    (ex: chateau-quinconces.feret.com)
                                </Typography>
                            </Box>
                        ),
                        tooltip: "",
                        extra: (
                            <>
                                <Box>
                                    En plus d&apos;être accessible depuis bordeaux.feret.com votre page publique pourra
                                    être accessible via une adresse personnalisée. (monchateau.feret.com ou
                                    monchateau.com par exemple)
                                </Box>
                                <Box>
                                    Toutes les informations que vous aurez saisies seront présentées dans une page
                                    isolée du site Féret, avec une adresse que vous pourrez communiquer à vos clients.
                                </Box>
                                <Box>Offrez-leur un accès direct et particulier à votre nouveau site web !</Box>
                            </>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (40 €/mois)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (40 €/mois)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (40 €/mois)
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "optionWriting",
                        title: (
                            <Typography variant="h6" key="">
                                Accompagnement rédactionnel
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Profitez des 200 ans d&apos;expérience des Éditions Féret et confiez-nous la rédaction
                                de l&apos;histoire de votre propriété. Les membres de notre équipe vous accompagneront
                                dans la rédaction de contenus de qualité afin de mettre en avant et de valoriser votre
                                propriété. Complétez ce service d&apos;une interview afin d&apos;étoffer votre contenu,
                                de recueillir des témoignages, et d&apos;apporter des éléments auxquels vous
                                n&apos;auriez peut-être pas pensé, mais que vous seul connaissez.
                            </Box>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (dès 500 €)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (dès 500 €)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (dès 500 €)
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "optionPhoto",
                        title: (
                            <Typography variant="h6" key="">
                                Reportage photo
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <>
                                <Box>
                                    <i>Bordeaux et ses Vins</i> en ligne vous propose un service photo pour enrichir
                                    votre présence en ligne. Notre partenaire, photographe professionnel et expérimenté
                                    dans le monde du vin, se déplacera chez vous afin d&apos;immortaliser chaque
                                    élément-clé de votre propriété.
                                </Box>
                                <br />
                                <Box>
                                    Offrez-vous un reportage photo comprenant des photos d&apos;illustration (chai,
                                    vignoble, propriété...), des photos de l&apos;équipe, de chacun de ses membres et
                                    des prises de vues aériennes par drone de votre propriété.
                                </Box>
                            </>
                        ),
                        cells: [
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (650 €)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (450 €)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (450 €)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (450 €)
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "optionPackshot",
                        title: (
                            <Typography variant="h6" key="">
                                Packshot bouteille
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <Box>
                                Disposez de supports visuels de qualité de chacune de vos bouteilles, réutilisables à
                                l&apos;infini.
                            </Box>
                        ),
                        cells: [
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (30 €/btl)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (25 €/btl)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (25 €/btl)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (25 €/btl)
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "optionStart",
                        title: (
                            <Typography variant="h6" key="">
                                Démarrage clé en main
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <>
                                <Box>
                                    Optimisez votre temps, nous vous accompagnons dans le démarrage de votre abonnement.
                                    Nous renseignons votre tableau de bord pour vous. Vous n’aurez plus qu’à profiter de
                                    nos nombreuses fonctionnalités.
                                </Box>
                                <br />
                                <Box>
                                    Notre équipe s’occupe de la saisie, optimise vos illustrations, vérifie et s’assure
                                    d’une haute qualité d’information.
                                </Box>
                                <br />
                                <Box>
                                    Ne vous occupez de rien, nos experts Féret sont là pour vous. Nous récupérons tous
                                    les documents nécessaires à la saisie de vos informations dans votre tableau de bord
                                    et nous en renseignons l&apos;intégralité. Tous les vins que vous souhaitez partager
                                    sur votre page publique, tous les membres, les illustrations de la propriété, ses
                                    installations, son terroir... Nous nous occupons de tout.
                                </Box>
                            </>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (500 €)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (500 €)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (`${STARTUP_HT}` €)
                                    </Typography>
                                ),
                            },
                        ],
                    },
                    {
                        id: "optionStudio",
                        title: (
                            <Typography variant="h6" key="">
                                Service studio fiche technique
                            </Typography>
                        ),
                        tooltip: "",
                        extra: (
                            <>
                                <Box>Vous manquez de temps et vous souhaitez une fiche technique à votre image ?</Box>
                                <br />
                                <Box>
                                    Faites appel à notre service studio, fournissez-nous vos éléments graphiques,
                                    exprimez-nous rapidement vos contraintes et envies, on s’occupe de tout.
                                </Box>
                                <br />
                                <Box>
                                    Vous obtiendrez alors un modèle de fiche technique réutilisable à l’infini pour tous
                                    vos millésimes !
                                </Box>
                            </>
                        ),
                        cells: [
                            {
                                title: "false",
                            },
                            {
                                title: "false",
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (250 €)
                                    </Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography variant="h6" key="">
                                        <b>Option</b> (250 €)
                                    </Typography>
                                ),
                            },
                        ],
                    },
                ]}
            />
        </Modal>
    );
};

ComparisonTablePopinContainer.propTypes = {
    subscriptionDispatch: func,
    modalOpen: bool,
    handleClose: func,
    collapse: bool,
    switchCheck: bool,
    handleSwitchChange: func,
    disabledHeader: bool,
    discountPrice: string,
    hideCTA: bool,
    disabledSwitch: bool,
};

export default ComparisonTablePopinContainer;
