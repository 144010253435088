import { Box, Hidden, makeStyles, Typography } from "@material-ui/core";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import PropTypes, { string } from "prop-types";
import { useEffect, useMemo, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import bgImage from "assets/tunnelImg.png";
import IconButton from "Components/Buttons/IconButton";
import SmallScreen from "Components/SmallScreen/SmallScreen";
import {
    ADD_BORDEAUX_PLUS,
    RECURRENCE_TYPES,
    SUBSCRIPTION_TYPES,
    SUPPORT_URL,
    SUBSCRIPTION_STATUS,
    URL_REDIRECT,
} from "config/constants";
import { setAdoptWineEntity } from "features/settings/settingsSlice";
import { refetchUser, signOut } from "features/user/userSlice";
import {
    useCreateNewSubscriptionDataMutation,
    useGenerateSubscriptionHostedPageMutation,
    useGetCommunesOptionsQuery,
    useGetSubscriptionDataQuery,
} from "generated/graphql";
import theme from "globalTheme";
import {
    addonWithSuffix,
    getPlanCode,
    getUserId,
    isInitialOrEssential,
    isOffline,
    useWindowDimensions,
} from "helpers/helpers";
import { useCustomHistory } from "hooks";
import { buildSubscritionDataInput } from "Routes/SubscriptionRouter/helper";
import SubscriptionHeader from "SubscriptionTunnel/Components/SubscriptionHeader";
import { allPrices } from "SubscriptionTunnel/constants";
import AboutPropertyContainer from "SubscriptionTunnel/Containers/AboutPropertyContainer";
import BasicRegistration from "SubscriptionTunnel/Containers/BasicRegistration";
import BillingContainer from "SubscriptionTunnel/Containers/BillingContainer";
import DeferredPayment from "SubscriptionTunnel/Containers/DeferredPayment";
import PaymentStepScreen from "SubscriptionTunnel/Containers/PaymentStepScreen";
import RoleIdentificationContainer from "SubscriptionTunnel/Containers/RoleIdentificationContainer";
import SubscriptionBackground from "SubscriptionTunnel/Containers/SubscriptionBackground";
import SubscriptionStatusCardsContainer from "SubscriptionTunnel/Containers/SubscriptionStatusCardsContainer";
import SubscriptionStepperContainer from "SubscriptionTunnel/Containers/SubscriptionStepperContainer";
import WebSubscriptionContainer from "SubscriptionTunnel/Containers/WebSubscriptionContainer";
import { toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles(() => ({
    background: {
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        background: "no-repeat",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    bodyContainer: {
        transition: "all .6s",
        ...theme.paddings.default.tunnel,
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "1rem",
    },
    headerContainer: {
        position: "sticky",
        top: "0",
        left: "0",
        width: "100%",
        zIndex: 15,
    },
    connectedCards: {
        transition: "all .6s",
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 2",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    connectedCardStep2: {
        transition: "all .6s",
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 2",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    centerCard: {
        transition: "all .6s",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1/ span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "1/ span 12",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "3 /span 8",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "4 / span 6",
        },
    },
    mainCardRole: {
        transition: "all .6s",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1 / 13",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / 13",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "2 / 12",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "3 / 11",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "4 / 10",
        },
    },
    mainCardYourWebSubscription: {
        transition: "all .6s",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "3 / span 10",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "3 / span 8",
        },
    },

    mainCardPropertyidentity: {
        transition: "all .6s",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "4 / span 6",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "5 / span 4",
        },
    },
    paymentForm: {
        transition: "all .6s",
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "3 / span 8",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "4 / span 6",
        },
        marginBottom: "4rem",
    },
    mainCardBilingForm: {
        transition: "all .6s",
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12 ",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "3 / span 8 ",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "4 / span 6 ",
        },
    },
    questionTypo: {
        fontWeight: "bold",
        fontFamily: "Lato",
        fontSize: "theme.typography.h3",
        textDecoration: "none",
    },
    hiddenBox: {
        position: "fixed",
        right: "80px",
        bottom: "80px",
    },
}));

// STEPPER
const stepperInitialState = {
    activeStep: 0,
    completedSteps: [false, false, false, false, false, false, false],
    disabledSteps: [true, true, true, true, true, true, true],
};

const stepperInitializer = (initialArg) => initialArg;

const stepperReducer = (state, action) => {
    let newCompletedSteps;
    switch (action.type) {
        case "next":
            newCompletedSteps = [...state.completedSteps];
            newCompletedSteps[state.activeStep] = true;
            return {
                ...state,
                activeStep: state.activeStep + 1,
                completedSteps: newCompletedSteps,
            };
        case "back":
            newCompletedSteps = [...state.completedSteps];
            newCompletedSteps[state.activeStep] = false;
            return {
                ...state,
                activeStep: state.activeStep - 1,
                completedSteps: newCompletedSteps,
            };
        case "navigate":
            return { ...state, activeStep: action.payload };
        case "goBackOnBadUrl":
            return {
                ...state,
                activeStep: action.payload.activeStep,
                completedSteps: [false],
                disabledSteps: [true],
            };
        default:
            throw new Error();
    }
};

const filterAddon = (value) => (addonCode) => addonCode !== value;

const subscriptionReducer = (state, action) => {
    switch (action.type) {
        case "setValue": {
            let addonCodes = state.addonCodes;
            if (action.payload.fieldName === "selectedPack" && !isInitialOrEssential(action.payload.fieldValue)) {
                addonCodes = addonCodes.filter((code) => code !== ADD_BORDEAUX_PLUS);
            }

            return {
                ...state,
                addonCodes,
                [action.payload.fieldName]: action.payload.fieldValue,
            };
        }
        case "setManyValues":
            return {
                ...state,
                ...action.payload,
            };
        case "initPaper":
            if (action.payload.selectedPaperValue === 2) {
                return {
                    ...state,
                    selectedPaper: action.payload.selectedPaperNewValue,
                };
            } else {
                return state;
            }
        case "toggleAddon": {
            if (!action.payload || typeof action.payload !== "string") return state;
            // If is already included, remove addon code
            if (state.addonCodes.includes(action.payload)) {
                const addonCodes = state.addonCodes.filter(filterAddon(action.payload));
                return { ...state, addonCodes };
            }
            // Add addon code
            return { ...state, addonCodes: [...state.addonCodes, action.payload] };
        }
        default:
            throw new Error();
    }
};

export const SubscriptionRouter = (props) => {
    const { pathname } = useLocation();
    const history = useCustomHistory();
    const dispatch = useDispatch();
    const [loadingCompo, setLoadingCompo] = useState(false);
    const [createSubloading, setCreateSubLoading] = useState(false);
    const [seeDiscount, setSeeDiscount] = useState(false);
    const [amountToPay, setAmountToPay] = useState(undefined);
    const [searchMode, setSearchMode] = useState(true);
    const [withPackPlusInitial, setWithPackPlusInitial] = useState(false);
    const [withPackPlusEssential, setWithPackPlusEssential] = useState(false);
    const { url } = useRouteMatch();

    // SWITCH
    const [isChecked, setIsChecked] = useState(true);

    const { refetchDone, currentUser } = useSelector((state) => state.user);
    const freeTrial = currentUser?.freeTrial;

    const adoptWineEntity = useSelector((state) => state?.settings?.adoptWineEntity);

    const { width } = useWindowDimensions();
    const styles = useStyles();

    const { data: communeData } = useGetCommunesOptionsQuery();
    const [stepperState, stepperDispatch] = useReducer(stepperReducer, stepperInitialState, stepperInitializer);

    const userId = useSelector(getUserId);
    const { data: { GetSubscriptionData: subscriptionData } = {}, loading } = useGetSubscriptionDataQuery({
        variables: { userId },
    });

    const [open, setOpen] = useState(false);

    // MUTATION
    const [createSubscriptionData, { error: createSubscriptionError }] = useCreateNewSubscriptionDataMutation();
    const [generateSubscriptionHostedPage] = useGenerateSubscriptionHostedPageMutation();
    // SUBSCRIPTION DATAS
    const subscriptionInitialState = {
        selectedRole: null,
        selectedPack: "essential",
        selectedPaper: "line",
        paymentMethod: null,
        addonCodes: [],

        subscriptionEstateName: null,
        subscriptionCommune: "",
        subscriptionMainAppellation: "",

        subscriptionContactTitle: "",
        subscriptionContactLastName: "",
        subscriptionContactFirstName: "",
        subscriptionContactEmailAddress: "",
        subscriptionContactPhoneNumber: "",
        subscriptionContactChecked: false,

        subscriptionNewsletter: false,
        subscriptionConditions: false,
    };

    const [subscriptionState, subscriptionDispatch] = useReducer(
        subscriptionReducer,
        subscriptionInitialState,
        (initialArg) => initialArg
    );

    const recurrence = useMemo(() => {
        const rec = isChecked ? RECURRENCE_TYPES.year : RECURRENCE_TYPES.month;
        return subscriptionState.selectedPack === SUBSCRIPTION_TYPES.basic ? RECURRENCE_TYPES.year : rec;
    }, [isChecked, subscriptionState]);

    useEffect(() => {
        if (props.isStandalone) {
            window.location.href = `${URL_REDIRECT}${location.pathname}`;
        }
    }, [location]);

    useEffect(() => {
        const unblock = history.block(() => {
            if (history.location?.pathname?.split("/")[2] === "registered") {
                return false;
            }
            return true;
        });

        return () => {
            unblock();
        };
    }, []);

    useEffect(() => {
        if (freeTrial) {
            if (refetchDone) {
                history.push("/dashboard/home");
                return;
            }
            setSearchMode(false);
            subscriptionDispatch({
                type: "setManyValues",
                payload: {
                    selectedRole: "estate",
                    selectedPack: "premium",
                    addonCodes: [],
                },
            });
            history.push("/subscription/2");
        }
    }, [freeTrial, refetchDone]);

    useEffect(() => {
        if (adoptWineEntity) {
            subscriptionDispatch({
                type: "setManyValues",
                payload: {
                    selectedRole: "estate",
                    orphanWineEntityId: adoptWineEntity?.wineEntityId,
                    subscriptionEstateName: adoptWineEntity.estateName,
                    subscriptionMainAppellation: adoptWineEntity.mainAppellation,
                    subscriptionCommune: adoptWineEntity.commune,
                    subscriptionAddress: adoptWineEntity.address,
                },
            });
            if (["/subscription/0", "/subscription", "/subscription/"].includes(pathname)) {
                history.push("/subscription/1");
            }
        }
    }, [adoptWineEntity]);

    useEffect(() => {
        if (createSubscriptionError) {
            toast.error("Cette raison sociale est déjà associée à un compte. Veuillez saisir une autre raison sociale");
            setOpen(false);
        }
    }, [createSubscriptionError]);

    const getHostedPageUrl = async (subscriptionInstance, subscriptionDataId) => {
        if (!subscriptionInstance) {
            // eslint-disable-next-line no-console
            console.error("Invalid subscriptionInstance");
            return;
        }

        // Get the hosted page URL
        const subscriptionType = subscriptionInstance?.selectedPack ?? subscriptionInstance.subscriptionType;
        const planCode = getPlanCode(subscriptionType, recurrence);
        const addonCodes = subscriptionInstance?.addonCodes.map((addon) => addonWithSuffix(addon, subscriptionType));

        const generatedUrl = await generateSubscriptionHostedPage({
            variables: {
                userId,
                planCode,
                addonCodes,
                subscriptionDataId,
            },
        });
        return generatedUrl?.data?.GenerateSubscriptionHostedPage;
    };

    useEffect(() => {
        if (subscriptionData) {
            if (isOffline(subscriptionData.paymentMethod)) history.replace("/subscription/registered");
            else history.replace("/paiement-en-cours");
        }
    }, [subscriptionData]);

    useEffect(() => {
        if (currentUser?.status === SUBSCRIPTION_STATUS.ACTIVE) history.push("/dashboard/home");
        if (currentUser) {
            subscriptionDispatch({
                type: "setManyValues",
                payload: {
                    subscriptionContactTitle: currentUser?.title,
                    subscriptionContactLastName: currentUser?.lastName,
                    subscriptionContactFirstName: currentUser?.firstName,
                    subscriptionContactEmailAddress: currentUser?.email,
                    subscriptionContactPhoneNumber: currentUser?.telephoneNumber,
                },
            });
        }
    }, [currentUser]);

    const handleSubscriptionTunnelMutation = async () => {
        const input = buildSubscritionDataInput(userId, subscriptionState, recurrence);

        // Create subscription data
        const res = await createSubscriptionData({ variables: { input } });
        const subscriptionDataId = res?.data?.CreateNewSubscriptionData;

        // Get hosted page URL and navigate to /paiement-en-cours
        const hostedPageUrl = await getHostedPageUrl(subscriptionState, subscriptionDataId);
        // Open hosted page
        window.open(hostedPageUrl, "_blank", "noreferrer");
        history.replace("/paiement-en-cours");

        props.openWelcomePopin(true);
    };

    // isChecked = facturationa annuelle vs mensuelle
    const handleSwitchChange = (event) => setIsChecked(event);

    // QUESTION MARK
    // eslint-disable-next-line no-unused-vars
    const getHelpQuestionMark = () => {
        return (
            <Hidden only={["xs", "sm", "md", "lg"]}>
                <Box className={styles.hiddenBox}>
                    <Link to={{ pathname: SUPPORT_URL }} target="_blank" style={{ textDecoration: "none" }}>
                        <IconButton variant="contained" color="primary">
                            <Typography variant="h23" className={styles.questionTypo}>
                                ?
                            </Typography>
                        </IconButton>
                    </Link>
                </Box>
            </Hidden>
        );
    };

    const checkIfPreviousStepIsValid = (currentStep) => {
        const stepOneIsValid = subscriptionState.selectedRole;
        const stepTwoIsValid = subscriptionState.selectedPack;
        const stepFourIsValid =
            subscriptionState.subscriptionCommune &&
            subscriptionState.subscriptionEstateName &&
            subscriptionState.subscriptionMainAppellation;
        const stepFiveIsValid = subscriptionState.subscriptionBillingZipCode;

        switch (currentStep) {
            case 1: // l'utilisateur doit avoir choisi un rôle
                return stepOneIsValid;
            case 2: // l'utilisateur doit avoir choisi un rôle et un type d'abo Web
                return stepOneIsValid && stepTwoIsValid;
            // case 3: // l'utilisateur doit avoir choisi un rôle et un type d'abo Web et d'abo papier
            //     return stepOneIsValid && stepTwoIsValid;
            case 3:
                return stepOneIsValid && stepTwoIsValid && stepFourIsValid;
            case 4:
                return stepOneIsValid && stepTwoIsValid && stepFourIsValid && stepFiveIsValid;
            case 5:
                return stepOneIsValid && stepTwoIsValid && stepFourIsValid && stepFiveIsValid;
            default:
                return false;
        }
    };

    const getCommune = (code) => {
        if (!code) return "";
        const commune = communeData?.GetCommunesOptions?.find((c) => c.communeINSEEcode === code);
        return commune ?? "";
    };

    const handleAboutPropertyNextClick = () => {
        stepperDispatch({ type: "next" });
        if (subscriptionState.selectedPack === SUBSCRIPTION_TYPES.basic && subscriptionState.selectedPaper === "line") {
            history.replace("/subscription/registered");
        } else {
            history.push("/subscription/3");
        }
    };

    const commune = getCommune(subscriptionState?.subscriptionBillingCommuneINSEEcode)?.simpleCommuneName;
    const withPlus = subscriptionState.addonCodes.includes(ADD_BORDEAUX_PLUS);

    // LEFT STATUS CARDS
    const getStatusCards = (style) => (
        <Box className={`${styles.connectedCards} ${style}`}>
            <SubscriptionStatusCardsContainer
                estateName={adoptWineEntity?.estateName}
                subscriptionState={subscriptionState}
                subscriptionStateSelectedRole={subscriptionState.selectedRole}
                subscriptionStateSelectedPack={subscriptionState.selectedPack}
                subscriptionStateSelectedPaper={subscriptionState.selectedPaper}
                subscriptionDispatch={subscriptionDispatch}
                webSubDisplayModifyLink={subscriptionState.selectedPack !== null}
                paperSubDisplayModifyLink={subscriptionState.selectedPaper !== null}
                switchCheck={isChecked}
                handleSwitchChange={(event) => handleSwitchChange(event)}
                onClickNext={() => {
                    // PAYLOAD 0
                    stepperDispatch({
                        type: "navigate",
                        payload: 1,
                    });
                    subscriptionDispatch({
                        type: "setManyValues",
                        payload: {
                            selectedPack: "essentiel",
                            selectedPaper: null,
                        },
                    });

                    history.push("/subscription/1");
                }}
                withPlus={
                    subscriptionState.selectedPack == SUBSCRIPTION_TYPES.initial
                        ? withPackPlusInitial
                        : withPackPlusEssential
                }
            />
        </Box>
    );

    return (
        <Box className={styles.background}>
            <ToastContainer
                autoClose={3500}
                draggable
                pauseOnHover
                pauseOnFocusLoss
                closeOnClick
                position="top-right"
            />
            <Switch>
                {/* Header, Stepper */}
                <Route path={url}>
                    <Box className={styles.headerContainer}>
                        <SubscriptionHeader onClick={() => history.push("/")} />
                        {pathname === `${url}/registered` ? (
                            <Box
                                style={{
                                    height: "50px",
                                    width: "100%",
                                    backgroundColor: theme.palette.primary.main,
                                    display: "flex",
                                    color: theme.palette.common.white,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "1rem",
                                }}
                            >
                                <CheckCircleOutlineRoundedIcon
                                    style={{
                                        fontSize: theme.typography.h3.fontSize,
                                        color: theme.palette.common.white,
                                        marginRight: "1rem",
                                    }}
                                />
                                <Typography style={{ fontWeight: 700 }} variant="h3">
                                    Merci, votre inscription est enregistrée
                                </Typography>
                            </Box>
                        ) : (
                            <SubscriptionStepperContainer
                                disableStepper={freeTrial}
                                selectedPack={subscriptionState.selectedPack}
                                selectedPaper={subscriptionState.selectedPaper}
                                subscriptionState={subscriptionState}
                                estateName={subscriptionState?.subscriptionEstateName}
                                activeStep={stepperState.activeStep}
                                completedSteps={stepperState.completedSteps}
                                disabledSteps={stepperState.disabledSteps}
                                stepsLabel={
                                    adoptWineEntity && [
                                        "Abonnement",
                                        `${adoptWineEntity?.estateName}`,
                                        "Facturation",
                                        "Paiement",
                                    ]
                                }
                                onClick={(_, index) => {
                                    stepperDispatch({
                                        type: "navigate",
                                        payload: index,
                                    });
                                }}
                            />
                        )}
                    </Box>
                </Route>
            </Switch>
            <Switch>
                {/* Route 0 : Role Identification and Other Regions Popin */}
                <Route exact path={[url, `${url}/0`]}>
                    <Box className={styles.bodyContainer}>
                        <Box className={styles.mainCardRole}>
                            <RoleIdentificationContainer
                                subscriptionDispatch={subscriptionDispatch}
                                subscriptionStateSelectedRole={subscriptionState?.selectedRole}
                                onClickNext={() => {
                                    stepperDispatch({ type: "next" });
                                    history.push("/subscription/1");
                                }}
                            />
                            {/* BETA */}
                            {/* <RoleIdentificationOtherRegionContainer /> */}
                        </Box>
                    </Box>
                </Route>
                {/* Route 1 : Web pack selection */}
                <Route path={`${url}/1`}>
                    <Box className={styles.bodyContainer}>
                        <>
                            {getStatusCards()}
                            <Box className={styles.mainCardYourWebSubscription}>
                                <WebSubscriptionContainer
                                    backLink
                                    titleButtonBack={adoptWineEntity && "Annuler et quitter"}
                                    seeDiscount={seeDiscount}
                                    setSeeDiscount={(e) => setSeeDiscount(e)}
                                    subscriptionDispatch={subscriptionDispatch}
                                    subscriptionStateSelectedPack={subscriptionState?.selectedPack}
                                    switchCheck={isChecked}
                                    handleSwitchChange={(event) => {
                                        handleSwitchChange(event);
                                    }}
                                    onClickBack={async () => {
                                        if (adoptWineEntity) {
                                            await dispatch(signOut());
                                            dispatch(setAdoptWineEntity(null));
                                        } else {
                                            stepperDispatch({
                                                type: "back",
                                            });
                                            history.push("/subscription/0");
                                        }
                                    }}
                                    onClickNext={() => {
                                        subscriptionDispatch({
                                            type: "initPaper",
                                            payload: {
                                                selectedPaperValue: subscriptionState.selectedPaper,
                                                selectedPaperNewValue: null,
                                            },
                                        });
                                        stepperDispatch({
                                            type: "next",
                                        });
                                        history.push("/subscription/2");
                                    }}
                                    checkIfPreviousStepIsValid={checkIfPreviousStepIsValid}
                                    withPlus={withPlus}
                                    handleChangewithPlus={(id) => {
                                        subscriptionDispatch({
                                            type: "toggleAddon",
                                            payload: ADD_BORDEAUX_PLUS,
                                        });

                                        if (id == SUBSCRIPTION_TYPES.initial) {
                                            setWithPackPlusInitial(!withPackPlusInitial);
                                        }
                                        if (id == SUBSCRIPTION_TYPES.essential) {
                                            setWithPackPlusEssential(!withPackPlusEssential);
                                        }
                                    }}
                                    withPackPlusInitial={withPackPlusInitial}
                                    withPackPlusEssential={withPackPlusEssential}
                                    reset={(id) => {
                                        if (id == SUBSCRIPTION_TYPES.initial && withPackPlusEssential) {
                                            setWithPackPlusEssential(false);
                                        }
                                        if (id == SUBSCRIPTION_TYPES.essential && withPackPlusInitial) {
                                            setWithPackPlusInitial(false);
                                        }
                                    }}
                                />
                            </Box>
                        </>
                    </Box>
                </Route>

                {/* Route 2 : Property Name, Commune, Appellation form */}
                <Route path={`${url}/2`}>
                    <Box className={styles.bodyContainer}>
                        <>
                            {getStatusCards(styles.connectedCardStep2)}
                            <Box className={styles.mainCardPropertyidentity}>
                                <AboutPropertyContainer
                                    open={open}
                                    onOpen={() => setOpen(true)}
                                    onClose={() => setOpen(false)}
                                    adoptingEstate={adoptWineEntity}
                                    subscriptionDispatch={subscriptionDispatch}
                                    subscriptionState={subscriptionState}
                                    allPrices={allPrices}
                                    switchCheck={isChecked}
                                    handleSwitchChange={(event) => handleSwitchChange(event)}
                                    onClickBack={() => {
                                        stepperDispatch({ type: "back" });
                                        history.push("/subscription/1");
                                    }}
                                    onClickNext={async () => {
                                        if (freeTrial && userId) {
                                            setCreateSubLoading(true);
                                            const input = buildSubscritionDataInput(
                                                userId,
                                                subscriptionState,
                                                recurrence
                                            );
                                            try {
                                                await createSubscriptionData({ variables: { input, freeTrial } });
                                                dispatch(refetchUser());
                                            } catch (e) {
                                                // Unused
                                            }
                                            setCreateSubLoading(false);
                                        } else {
                                            handleAboutPropertyNextClick();
                                        }
                                    }}
                                    checkIfPreviousStepIsValid={checkIfPreviousStepIsValid}
                                    searchMode={searchMode}
                                    setSearchMode={(e) => setSearchMode(e)}
                                    createSubloading={createSubloading}
                                />
                            </Box>
                        </>
                    </Box>
                </Route>

                {/* Route 3 : Billing form */}
                <Route path={`${url}/3`}>
                    <Box className={styles.bodyContainer}>
                        <>
                            {getStatusCards()}
                            <Box className={styles.mainCardBilingForm}>
                                <BillingContainer
                                    subscriptionDispatch={subscriptionDispatch}
                                    subscriptionState={subscriptionState}
                                    onClickNext={() => {
                                        stepperDispatch({ type: "next" });
                                        history.push("/subscription/4");
                                    }}
                                    onClickBack={() => {
                                        stepperDispatch({ type: "back" });
                                        history.push("/subscription/2");
                                    }}
                                    checkIfPreviousStepIsValid={checkIfPreviousStepIsValid}
                                />
                            </Box>
                        </>
                    </Box>
                </Route>
                {/* Route 4 : Payment form */}
                <Route path={`${url}/4`}>
                    <Box className={styles.bodyContainer}>
                        <Box className={styles.paymentForm}>
                            <PaymentStepScreen
                                setAmountToPay={setAmountToPay}
                                comune={commune}
                                checkIfPreviousStepIsValid={checkIfPreviousStepIsValid}
                                stepperDispatch={stepperDispatch}
                                subscriptionState={subscriptionState}
                                allPrices={allPrices}
                                switchCheck={isChecked}
                                isLoading={loadingCompo}
                                selectPayment={subscriptionState.paymentMethod}
                                setSelectPayment={(fieldValue) => {
                                    subscriptionDispatch({
                                        type: "setValue",
                                        payload: { fieldName: "paymentMethod", fieldValue },
                                    });
                                }}
                                onClickNext={async () => {
                                    if (isOffline(subscriptionState.paymentMethod)) {
                                        history.push("/subscription/5");
                                    } else {
                                        setLoadingCompo(true);
                                        await handleSubscriptionTunnelMutation();
                                        setLoadingCompo(false);
                                    }
                                }}
                                withPlus={
                                    subscriptionState.selectedPack == SUBSCRIPTION_TYPES.initial
                                        ? withPackPlusInitial
                                        : withPackPlusEssential
                                }
                            />
                        </Box>
                    </Box>
                </Route>

                {/* Route 5 : Deffered payment */}
                {isOffline(subscriptionState.paymentMethod) && (
                    <Route path={`${url}/5`}>
                        <Box className={styles.bodyContainer}>
                            <Box className={styles.centerCard}>
                                <DeferredPayment
                                    subscriptionFlow
                                    amountToPay={amountToPay}
                                    switchCheck={isChecked}
                                    subscriptionState={subscriptionState}
                                    comune={commune}
                                    selectPayment={subscriptionState.paymentMethod}
                                    onClickBack={() => history.push("/subscription/4")}
                                    onClickNext={() => history.replace("/subscription/registered")}
                                />
                            </Box>
                        </Box>
                    </Route>
                )}

                <Route path={`${url}/registered`}>
                    <SubscriptionBackground>
                        <BasicRegistration
                            subscriptionData={subscriptionData}
                            subscriptionState={subscriptionState}
                            recurrence={recurrence}
                            loading={loading}
                        />
                    </SubscriptionBackground>
                </Route>
            </Switch>
            {/* {getHelpQuestionMark()} */}
            {width < 600 && (
                <SmallScreen
                    text={
                        "Pour préserver la qualité de votre expérience, veuillez poursuivre votre navigation sur une tablette, un ordinateur ou pivoter l’écran de votre téléphone pour passer en mode paysage."
                    }
                />
            )}
        </Box>
    );
};

SubscriptionRouter.propTypes = {
    welcomePopin: PropTypes.bool,
    openWelcomePopin: PropTypes.func,
    isStandalone: string,
};
