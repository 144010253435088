import { Box, Hidden, Link, makeStyles, Typography } from "@material-ui/core";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import DividerWithText from "Components/Divider/DividerWithText";
import { SUBSCRIPTION_TYPES } from "config/constants";
import PropTypes, { bool, func, string } from "prop-types";
import { useState } from "react";

import apercu from "../../assets/icons/custom/apercu.svg";
import mini_page from "../../assets/SubscriptionPage/preview_estate_page.png";
import Arrow from "../../Components/Arrow/Arrow";
import IconButton from "../../Components/Buttons/IconButton";
import theme from "../../globalTheme";
import PackDescription from "../Components/PackDescription/PackDescription";
import { allPrices, PACKS } from "../constants";
import ComparisonTablePopinContainer from "../Containers/ComparisonTablePopinContainer";
import SubscriptionContainer from "../Containers/SubscriptionContainer";
import EstateZoomPopin from "../Popins/EstateZoomPopin";
import { Redirect } from "Components/Redirect/Redirect";

const useStyles = makeStyles(() => ({
    boxApercu: {
        position: "absolute",
        zIndex: 2,
        right: "0px",
        top: "0px",
        [theme.breakpoints.up("sm")]: {
            transform: "translate(48%, -43%)",
        },
        [theme.breakpoints.up("md")]: {
            transform: "translate(66%, -34%)",
        },
        [theme.breakpoints.up("lg")]: {
            transform: "translate(66%, -34%)",
        },
        [theme.breakpoints.up("xl")]: {
            transform: "translate(66%, -34%)",
        },
    },
    packs_container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        paddingTop: "1%",
    },
    divider_dashed_container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    and_icon: {
        color: theme.palette.primary.main,
        width: "24px",
        height: "24px",
        textAlign: "center",
        fontWeight: 700,
    },
}));

export const getPricesWithOption = (item, switchCheck, withPlus, packPlusPrice = 150) => {
    if (item.id === SUBSCRIPTION_TYPES.basic) {
        return item.price;
    } else if (item.id === SUBSCRIPTION_TYPES.initial || item.id === SUBSCRIPTION_TYPES.essential) {
        if (switchCheck) {
            if (withPlus) {
                return allPrices[item.id]["htNumber"] + packPlusPrice + " € HT";
            } else {
                return allPrices[item.id]["htNumber"] + " € HT";
            }
        } else {
            if (withPlus) {
                return (allPrices[item.id]["htMonthly"] + packPlusPrice / 12).toFixed(1) + " € HT";
            } else {
                return allPrices[item.id]["htMonthly"] + " € HT";
            }
        }
    } else if (item.id === SUBSCRIPTION_TYPES.premium) {
        if (switchCheck) {
            return allPrices[item.id]["htNumber"] + " € HT";
        } else {
            return allPrices[item.id]["htMonthly"] + " € HT";
        }
    }
};

const WebSubscriptionContainer = (props) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const packsDescription = [PACKS.basic, PACKS.initial, PACKS.essential, PACKS.premium];

    const handleChangePackWithPlus = (id) => {
        props.handleChangewithPlus(id);
    };

    const styles = useStyles();
    if (props.checkIfPreviousStepIsValid(1)) {
        return (
            <>
                <SubscriptionContainer
                    title={
                        <Box display="flex" flexDirection="column">
                            Votre abonnement
                            <Link
                                variant="body2"
                                color="primary"
                                underline="always"
                                style={{
                                    fontFamily: "Mansalva",
                                    cursor: "pointer",
                                }}
                                onClick={() => setOpen(true)}
                            >
                                voir le comparatif des abonnements
                            </Link>
                        </Box>
                    }
                    titleVariant="h3"
                    buttonLabel="Suivant"
                    disabled={props.subscriptionStateSelectedPack !== null ? false : true}
                    backLink={props.backLink}
                    onClickNext={props.onClickNext}
                    onClickBack={props.onClickBack}
                    style={{ width: "100%" }}
                    priceSwitch={true}
                    switchCheck={props.switchCheck}
                    handleSwitchChange={(event) => props.handleSwitchChange(event)}
                    subscriptionStateSelectedPack={props.subscriptionStateSelectedPack}
                    typeSubscriptionPopin={"estate"}
                    titleButtonBack={props.titleButtonBack}
                    buttonNext
                >
                    <Box style={{ display: "flex", justifyContent: "space-between", gap: "32px" }}>
                        <Hidden smDown>
                            <Box style={{ paddingTop: "2%", position: "relative", maxWidth: "20%" }}>
                                <Box className={styles.boxApercu}>
                                    <Arrow
                                        rotation="-155"
                                        arrowPosition="start"
                                        style={{ marginLeft: "25px", padding: 0 }}
                                    >
                                        <Typography variant="body2" color="primary" style={{ fontFamily: "Mansalva" }}>
                                            aperçu
                                        </Typography>
                                    </Arrow>
                                    <EstateZoomPopin
                                        clickableElement={
                                            <IconButton variant="contained" color="secondary" size="small">
                                                <img src={apercu} />
                                            </IconButton>
                                        }
                                        subscriptionStateSelectedPack={props.subscriptionStateSelectedPack}
                                    />
                                </Box>
                                <img
                                    src={mini_page}
                                    alt="subscription page"
                                    width="100%"
                                    style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.20)" }}
                                />
                            </Box>
                        </Hidden>
                        <Box className={styles.packs_container}>
                            {packsDescription.map((item, i) => {
                                let key = `pack-${i}`;
                                return (
                                    <>
                                        {i === 0 && (
                                            <>
                                                <DividerWithText>
                                                    <ArrowDownwardRoundedIcon
                                                        style={{ color: theme.palette.primary.main }}
                                                    />
                                                </DividerWithText>
                                                <Typography
                                                    variant="h5"
                                                    style={{
                                                        textTransform: "uppercase",
                                                        color: theme.palette.primary.main,
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <span style={{ fontWeight: 300 }}>profitez de la</span>{" "}
                                                    <b>notoriété bicentenaire de Féret</b>
                                                </Typography>
                                            </>
                                        )}
                                        <PackDescription
                                            key={key}
                                            discountPriceChildren={props.switchCheck && item.discount}
                                            tooltip={item.tooltip}
                                            logoSubscriptionType={item.logo}
                                            tagLabel={item.tagLabel}
                                            displayTag={item.displayTag}
                                            selected={props.subscriptionStateSelectedPack === item.id}
                                            titleChildren={item.label}
                                            priceChildren={getPricesWithOption(
                                                item,
                                                props.switchCheck,
                                                item.id === SUBSCRIPTION_TYPES.initial
                                                    ? props.withPackPlusInitial
                                                    : props.withPackPlusEssential,
                                                item.id === SUBSCRIPTION_TYPES.initial ? 150 : 200
                                            )}
                                            periodChildren={
                                                item.id === SUBSCRIPTION_TYPES.basic
                                                    ? item.period
                                                    : props.switchCheck
                                                    ? "/an"
                                                    : "/mois"
                                            }
                                            disabled={item.disabled}
                                            onChangeSelected={() => {
                                                props.reset(item.id);
                                                props.subscriptionDispatch({
                                                    type: "setValue",
                                                    payload: { fieldName: "selectedPack", fieldValue: item.id },
                                                });
                                            }}
                                            // withPlus={props.withPlus}
                                            withPlus={
                                                item.id === SUBSCRIPTION_TYPES.initial
                                                    ? props.withPackPlusInitial
                                                    : props.withPackPlusEssential
                                            }
                                            handleChangewithPlus={() => handleChangePackWithPlus(item.id)}
                                            //new
                                            include={item.include}
                                            exclude={item?.exclude}
                                            checkbox={item?.checkbox}
                                            description={item?.description}
                                        />
                                        {i === 1 && (
                                            <Box className={styles.divider_dashed_container} style={{}}>
                                                <Box width="50%">
                                                    <DividerWithText dashed>
                                                        <Typography className={styles.and_icon} variant="h5">
                                                            ET
                                                        </Typography>
                                                    </DividerWithText>
                                                </Box>
                                                <Typography
                                                    variant="h5"
                                                    style={{
                                                        textTransform: "uppercase",
                                                        color: theme.palette.primary.main,
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <span style={{ fontWeight: 300 }}>accédez au</span>{" "}
                                                    <b> puissant gestionnaire de fiches techniques</b>
                                                </Typography>
                                            </Box>
                                        )}
                                    </>
                                );
                            })}

                            <Box>
                                {open && (
                                    <ComparisonTablePopinContainer
                                        collapse
                                        discountPrice={packsDescription[2].discount}
                                        modalOpen={open}
                                        handleClose={handleClose}
                                        subscriptionDispatch={props.subscriptionDispatch}
                                        switchCheck={props.switchCheck}
                                        handleSwitchChange={(event) => props.handleSwitchChange(event)}
                                    />
                                )}

                                <Arrow arrowPosition="start" horizontal="right" vertical="up" rotation="105">
                                    <Link
                                        variant="body2"
                                        color="primary"
                                        underline="always"
                                        style={{
                                            fontFamily: "Mansalva",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setOpen(true)}
                                    >
                                        Comparatif des abonnements
                                    </Link>
                                </Arrow>
                            </Box>
                        </Box>
                    </Box>
                </SubscriptionContainer>
            </>
        );
    } else {
        return <Redirect to={{ pathname: "/subscription/0" }} />;
    }
};

WebSubscriptionContainer.propTypes = {
    subscriptionStateSelectedPack: PropTypes.number,
    subscriptionDispatch: PropTypes.func,
    checkIfPreviousStepIsValid: PropTypes.func,

    onClickNext: PropTypes.func,
    onClickBack: PropTypes.func,

    switchCheck: PropTypes.bool,
    handleSwitchChange: PropTypes.func,
    seeDiscount: bool,
    setSeeDiscount: func,
    titleButtonBack: string,
    withPackPlusEssential: bool,
    withPackPlusInitial: bool,
    reset: func,

    withPlus: bool,
    handleChangewithPlus: func,
};

export default WebSubscriptionContainer;
