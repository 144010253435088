import { Trans } from "@lingui/macro";
import {
    Box,
    Collapse,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { array, bool, func, number, string } from "prop-types";
import { useRef, useState } from "react";

import FreeTrialBanner from "Dashboard/Components/RestrictionSubscription/freeTrial/FreeTrialBanner";
import theme from "../../globalTheme";
import { useWindowDimensions } from "../../helpers/helpers";
import PlusChip from "./PlusChip";

const useStyle = makeStyles(() => ({
    container_table: {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            overflowX: "auto",
            scrollBehavior: "smooth",
        },
        [theme.breakpoints.up("xl")]: {
            overflowX: "unset",
        },
    },
    button: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        "&:disabled": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    button_disabled: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    table: {
        tableLayout: "fixed",
        borderCollapse: "separate",
        [theme.breakpoints.up("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.up("xl")]: {
            width: "auto",
        },
    },
    fix: (props) => {
        return {
            ...theme.typography.h5,
            position: "sticky",
            left: 0,
            width: "284px",
            padding: "0.5rem 1rem",
            zIndex: 1,
            color: theme.palette.primary.dark,
            backgroundColor: props.backgroundColor || theme.palette.background.card,
            borderBottom: `1px solid ${theme.palette.secondary.dark}`,
        };
    },
    cell: {
        ...theme.typography.h5,
        [theme.breakpoints.up("sm")]: {
            width: "250px",
        },
        [theme.breakpoints.up("md")]: {
            width: "260px",
        },
        [theme.breakpoints.up("lg")]: {
            width: "260px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "350px",
        },
        color: theme.palette.primary.dark,
        textAlign: "center ",
        padding: "0.5rem 0",
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    row: (props) => {
        return {
            "&:nth-child(1)": {
                "& td": {
                    border: "none",
                    padding: "1rem 0.5rem",
                    backgroundColor: `${props.backgroundColor || theme.palette.background.card} !important`,
                },
            },
            "&:nth-child(2)": {
                "& td": {
                    padding: "0.5rem 1rem",
                    backgroundColor: `${props.backgroundColor || theme.palette.background.card} !important`,
                    "&:first-child": {
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                    },
                    "&:last-child": {
                        borderTopRightRadius: "5px",
                        borderBottomRightRadius: "5px",
                    },
                },
            },
        };
    },
    navigation: {
        [theme.breakpoints.up("sm")]: {
            display: "flex",
            margin: "2rem 0",
            justifyContent: "end",
            marginRight: "4rem",
        },
        [theme.breakpoints.up("md")]: {
            display: "flex",
            margin: "2rem 0",
            marginRight: "0",
            justifyContent: "center",
        },
        [theme.breakpoints.up("xl")]: {
            display: "none",
        },
    },
    showExpand: {
        [theme.breakpoints.up("sm")]: {
            width: "2rem",
        },
        [theme.breakpoints.up("lg")]: {
            width: "2rem",
        },
        [theme.breakpoints.up("xl")]: {
            width: "3rem",
        },
    },
    explanations: {
        [theme.breakpoints.up("xs")]: {
            width: "80vw",
        },
        [theme.breakpoints.up("lg")]: {
            width: "100%",
        },
    },
    withPlus: {
        fontWeight: "900",
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.dark,
        width: 120,
        margin: "0 auto",
        borderRadius: 3,
    },
}));

const SubscriptionsType = (props) => {
    const styles = useStyle(props);
    const ref = useRef();
    const [hover, setHover] = useState(null);
    const [index, setIndex] = useState(1);

    const generateCell = (item, id, className) => {
        const backgroundColor =
            hover === id ? theme.palette.common.white : props.backgroundColor || theme.palette.background.card;

        if (["true", "false"].includes(item)) {
            return (
                <TableCell
                    id={id}
                    className={className}
                    onMouseEnter={() => setHover(id)}
                    onMouseLeave={() => setHover(null)}
                    style={{ backgroundColor }}
                >
                    {item === "true" ? (
                        <CheckCircleRoundedIcon style={{ color: theme.palette.success.dark }} />
                    ) : (
                        <CancelRoundedIcon style={{ color: theme.palette.secondary.main }} />
                    )}
                </TableCell>
            );
        }
        if ("truePack" === item) {
            return (
                <TableCell
                    id={id}
                    className={className}
                    onMouseEnter={() => setHover(id)}
                    onMouseLeave={() => setHover(null)}
                >
                    <CheckCircleOutlinedIcon className={styles.withPlus} />
                </TableCell>
            );
        }
        return (
            <TableCell
                id={id}
                className={className}
                onMouseEnter={() => setHover(id)}
                onMouseLeave={() => setHover(null)}
                style={{ backgroundColor }}
            >
                <Typography variant="h5">{item}</Typography>
            </TableCell>
        );
    };

    const { width } = useWindowDimensions();

    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    };

    return (
        <>
            {width < props.arrowScrollWidth && (
                <Box className={styles.navigation}>
                    <IconButton
                        style={{ marginRight: "3rem" }}
                        classes={{
                            root: styles.button,
                            disabled: styles.button_disabled,
                        }}
                        className={styles.button}
                        disabled={index === 1}
                        onClick={() => {
                            if (width >= 0 && width < 960) {
                                scroll(-266);
                                setIndex(index - 1);
                            } else {
                                scroll(-530);
                                setIndex(index - 3);
                            }
                        }}
                    >
                        <ArrowBackIcon style={{ color: theme.palette.common.white }} />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            if (width >= 0 && width < 960) {
                                scroll(266);
                                setIndex(index + 1);
                            } else {
                                scroll(530);
                                setIndex(index + 3);
                            }
                        }}
                        classes={{
                            root: styles.button,
                            disabled: styles.button_disabled,
                        }}
                        disabled={index === 4}
                    >
                        <ArrowForwardIcon style={{ color: theme.palette.common.white }} />
                    </IconButton>
                </Box>
            )}
            {width >= 1280 && props.showFreeTrialBanner && <FreeTrialBanner />}
            <Box className={styles.container_table} id="container_table" ref={ref}>
                <Table className={styles.table}>
                    <TableBody>
                        {props.data?.map((d) => (
                            <>
                                <TableRow
                                    className={styles.row}
                                    key=""
                                    onClick={() => {
                                        if (d.extra) props.setExpandItem(props.expandItem !== d.id ? d.id : "");
                                    }}
                                >
                                    <TableCell id="fix" className={`${props.cellFixClassName} ${styles.fix}`} key="">
                                        <Box style={props.collapse && { display: "flex", alignItems: "center" }}>
                                            {props.collapse && d.extra && (
                                                <IconButton
                                                    className={styles.showExpand}
                                                    variant="text"
                                                    color="primary"
                                                    size="small"
                                                    style={{
                                                        transform:
                                                            props.expandItem === d.id
                                                                ? "rotate(180deg)"
                                                                : "rotate(0deg)",
                                                    }}
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                            )}
                                            {d.title}
                                        </Box>
                                    </TableCell>
                                    {d.cells.map((cell, i) => generateCell(cell.title, i, props.cellClassName))}
                                </TableRow>
                                {props.collapse && d.extra && props.expandItem === d.id && (
                                    <TableCell colSpan={6} style={{ backgroundColor: theme.palette.background.card }}>
                                        <Collapse in={props.expandItem === d.id} timeout="auto" unmountOnExit>
                                            <Typography variant="h6" color="primary" className={styles.explanations}>
                                                {d.extra}
                                            </Typography>
                                        </Collapse>
                                    </TableCell>
                                )}
                            </>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <Typography variant="h6" style={{ color: theme.palette.primary.dark }}>
                <Trans>*notes : sous réserve de disposer des droits d&apos;usage</Trans>
            </Typography>
            <Box display="flex" justifyContent="start" alignItems="center" style={{ gap: "4px", marginTop: "1rem" }}>
                <PlusChip />
                <Typography variant="h5" style={{ fontFamily: "Mansalva", color: theme.palette.primary.dark }}>
                    <Trans>
                        choisissez l’option PLUS lors de votre souscription et bénéficiez d’une version plus riche,
                        moins limitée !
                    </Trans>
                </Typography>
            </Box>
        </>
    );
};

SubscriptionsType.propTypes = {
    collapse: bool,
    data: array,
    setExpandItem: func,
    expandItem: string,
    backgroundColor: string,
    cellClassName: string,
    arrowScrollWidth: number,
    cellFixClassName: string,
    showFreeTrialBanner: bool,
};

export default SubscriptionsType;
