import { arrayOf, bool, string } from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import Page404 from "Components/ErrorPage/Page404";
import BrowsingPreferenceModal from "Front/browsingPreference/BrowsingPreferenceModal";
import FrontFooter from "Front/footer/FrontFooter";
import FrontNavbar from "Front/FrontNavbar";
import FrontActivityPage from "Pages/ActivityPage/ActivityPage";
import CGUPage from "Pages/CGUPage";
import { FrontEstatePage } from "Pages/EstatePage/EstatePage";
import LegalNoticePage from "Pages/LegalNoticePage";

import ScrollToTop from "Containers/ScrollToTop";
import AllAppellationsPage from "Pages/AllAppellationsPage";
import AppearsInBESV from "Pages/AppearsInBESV";
import AppellationPage from "Pages/AppellationPage";
import AuthorPage from "Pages/AuthorPage";
import ChallengePage from "Pages/ChallengePage";
import ClassificationsPage from "Pages/ClassificationsPage";
import CommunePage from "Pages/CommunePage";
import ContactPage from "Pages/ContactPage";
import EncyclopediaPage from "Pages/EncyclopediaPage";
import FormationPage from "Pages/FormationPage";
import OdgPage from "Pages/OdgPage";
import OrganizationPage from "Pages/OrganizationPage";
import OrganizationsPage from "Pages/OrganizationsPage";
import PostPage from "Pages/PostPage";
import RankingPage from "Pages/RankingPage";
import FeretLetterPage from "Pages/FeretLetterPage";
import FeretLetterTwoPage from "Pages/FeretLetterTwoPage";
import FeretLetterIndexPage from "Pages/FeretLetterIndexPage";
import MillesimesPage from "Pages/MillesimesPage";
import { ConnectedQrcodeWinePage, FrontConnectedWinePage, RedirectPage } from "Pages/WinePage";
import { ALL_LANGUAGES, FR_AND_EN } from "config/constants";
import RoomTastingPage from "Pages/RoomTastingPage";
import JournalistPage from "Pages/JournalistPage";
import { Helmet } from "react-helmet";
import { RestrictedLanguageRoute } from "Routes/RestrictedLanguageRoute";

export const SEOLinks = ({ languages }) => {
    const { params } = useRouteMatch();
    const location = useLocation();
    const baseURL = "https://bordeaux.feret.com";
    const { locale: pageLocale } = params;
    const settingsLocale = useSelector((state) => state?.user?.locale || "fr");
    const locale = pageLocale || settingsLocale;
    const pathname = `/${location.pathname.split(`${locale}/`).pop()}`;

    const generateLink = (lang) => (
        <link key={lang} rel="alternate" href={`${baseURL}/${lang}${pathname}`} hrefLang={lang} />
    );

    const canonicalLink = <link rel="canonical" href={`${baseURL}/${locale}${pathname}`} />;

    return (
        <Helmet>
            {canonicalLink}
            {languages.map((lang) => generateLink(lang))}
        </Helmet>
    );
};

SEOLinks.propTypes = {
    languages: arrayOf(string),
};

const FrontRoutes = () => {
    const history = useHistory();
    const [warningBrowserLanguage, setWarningBrowserLanguage] = useState(false);
    const { url } = useRouteMatch();

    return (
        <>
            <BrowsingPreferenceModal
                open={warningBrowserLanguage}
                onClickBack={() => history.goBack()}
                onClose={() => setWarningBrowserLanguage(false)}
            />
            <FrontNavbar />
            <ScrollToTop />

            <Switch>
                {/*---------------------- LA LETTRE FERET ----------------------- */}
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/la-lettre-feret-sommaire`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <FeretLetterIndexPage/>
                </RestrictedLanguageRoute>
                
                {/*---------------------- LA LETTRE FERET ----------------------- */}
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/la-lettre-feret`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <FeretLetterPage/>
                </RestrictedLanguageRoute>

                {/*---------------------- LA LETTRE FERET n°2 ----------------------- */}
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/la-lettre-feret-ed-2`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <FeretLetterTwoPage/>
                </RestrictedLanguageRoute>

                {/*--------------------- NOTE DU MILLÉSIME ---------------------- */}
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/note-du-millesime`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <MillesimesPage />
                </RestrictedLanguageRoute>

                {/*--------------------------- AUTHOR --------------------------- */}
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/encyclopedie-feret/auteur/:name`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <AuthorPage />
                </RestrictedLanguageRoute>
                <Redirect from="/encyclopedie-feret/auteur" to="/encyclopedie-feret" />

                {/*--------------------------- ARTICLE --------------------------- */}
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/encyclopedie-feret/article/:title`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <PostPage />
                </RestrictedLanguageRoute>
                <Redirect from="/encyclopedie-feret/article" to="/encyclopedie-feret" />

                {/*--------------------------- ENCYCLOPEDIE --------------------------- */}
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/encyclopedie-feret`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <EncyclopediaPage />
                </RestrictedLanguageRoute>

                {/*--------------------------- TASTING --------------------------- */}

                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/salle-de-degustation/:name`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <JournalistPage />
                </RestrictedLanguageRoute>

                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/salle-de-degustation`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <RoomTastingPage />
                </RestrictedLanguageRoute>

                {/*--------------------------- APPELLATION ---------------------------*/}
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/appellations/:region/:appellation`}>
                    <SEOLinks languages={FR_AND_EN} />

                    <AppellationPage />
                </RestrictedLanguageRoute>
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/appellations/:region`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <AllAppellationsPage />
                </RestrictedLanguageRoute>
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/appellations`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <AllAppellationsPage />
                </RestrictedLanguageRoute>

                {/*--------------------------- CLASSEMENTS ---------------------------*/}
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/les-classements-de-bordeaux/:name`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <RankingPage />
                </RestrictedLanguageRoute>
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/les-classements-de-bordeaux`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <ClassificationsPage />
                </RestrictedLanguageRoute>

                {/*--------------------------- ODG --------------------------- */}
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/organismes-vignoble-bordelais/ODG/:name`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <OdgPage />
                </RestrictedLanguageRoute>

                {/*--------------------------- FORMATION --------------------------- */}
                <RestrictedLanguageRoute
                    languages={FR_AND_EN}
                    path={`${url}/organismes-vignoble-bordelais/formation/:name`}
                >
                    <SEOLinks languages={FR_AND_EN} />
                    <FormationPage />
                </RestrictedLanguageRoute>

                {/*--------------------------- REPRESENTATION --------------------------- */}
                <RestrictedLanguageRoute
                    languages={FR_AND_EN}
                    path={`${url}/organismes-vignoble-bordelais/representation/:id`}
                >
                    <SEOLinks languages={FR_AND_EN} />
                    <OrganizationPage />
                </RestrictedLanguageRoute>

                {/*--------------------------- CONCOURS ---------------------------*/}
                <RestrictedLanguageRoute
                    languages={FR_AND_EN}
                    path={`${url}/organismes-vignoble-bordelais/concours/:challenge`}
                >
                    <SEOLinks languages={FR_AND_EN} />
                    <ChallengePage />
                </RestrictedLanguageRoute>

                {/*--------------------------- ORGANISMES ---------------------------*/}
                <RestrictedLanguageRoute
                    languages={FR_AND_EN}
                    path={`${url}/organismes-vignoble-bordelais/:organization`}
                >
                    <SEOLinks languages={FR_AND_EN} />
                    <OrganizationsPage />
                </RestrictedLanguageRoute>

                <Redirect
                    from={`${url}/organismes-vignoble-bordelais`}
                    to={`${url}/organismes-vignoble-bordelais/ODG`}
                />

                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/figurer-dans-bordeaux-et-ses-vins`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <AppearsInBESV />
                </RestrictedLanguageRoute>

                {/*--------------------------- CGU, LEGAL NOTICES --------------------------- */}
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/confidentialite-CGU`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <CGUPage />
                </RestrictedLanguageRoute>

                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/mentions-legales`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <LegalNoticePage />
                </RestrictedLanguageRoute>

                {/*--------------------------- VINTAGE --------------------------- */}
                <RestrictedLanguageRoute
                    languages={ALL_LANGUAGES}
                    path={`${url}/:wineEntityShortId-:propertyName/vins/:wineShortId-:wineName/:year-:cuvee`}
                >
                    <SEOLinks languages={ALL_LANGUAGES} />
                    <FrontConnectedWinePage />
                </RestrictedLanguageRoute>

                {/*--------------------------- WINE --------------------------- */}
                <RestrictedLanguageRoute
                    languages={ALL_LANGUAGES}
                    path={`${url}/:wineEntityShortId-:propertyName/vins/:wineShortId-:wineName/:year`}
                >
                    <SEOLinks languages={ALL_LANGUAGES} />
                    <FrontConnectedWinePage />
                </RestrictedLanguageRoute>

                {/*--------------------------- WINE --------------------------- */}
                <RestrictedLanguageRoute
                    languages={ALL_LANGUAGES}
                    path={`${url}/:wineEntityShortId-:propertyName/vins/:wineShortId-:wineName`}
                >
                    <SEOLinks languages={ALL_LANGUAGES} />
                    <FrontConnectedWinePage />
                </RestrictedLanguageRoute>

                {/*--------------------------- ACTIVITY --------------------------- */}
                <RestrictedLanguageRoute
                    languages={ALL_LANGUAGES}
                    path={`${url}/:wineEntityShortId-:propertyName/activites/:activityShortId-:activityName`}
                >
                    <SEOLinks languages={ALL_LANGUAGES} />
                    <FrontActivityPage />
                </RestrictedLanguageRoute>
                <Redirect from="/:wineEntityShortId-:propertyName/vins" to="/:wineEntityShortId-:propertyName#vins" />
                <Redirect
                    from="/:wineEntityShortId-:propertyName/activites"
                    to="/:wineEntityShortId-:propertyName#activites"
                />

                {/*--------------------------- PROPERTY --------------------------- */}
                <RestrictedLanguageRoute languages={ALL_LANGUAGES} path={`${url}/:wineEntityShortId-:propertyName`}>
                    <SEOLinks languages={ALL_LANGUAGES} />
                    <FrontEstatePage />
                </RestrictedLanguageRoute>
                <Redirect from="/:wineEntityShortId-:propertyName/vins" to="/:wineEntityShortId-:propertyName#vins" />
                <Redirect
                    from="/:wineEntityShortId-:propertyName/activites"
                    to="/:wineEntityShortId-:propertyName#activites"
                />

                {/*--------------------------- COMMUNE --------------------------- */}
                <RestrictedLanguageRoute languages={FR_AND_EN} path={`${url}/commune/:name`}>
                    <SEOLinks languages={FR_AND_EN} />
                    <CommunePage />
                </RestrictedLanguageRoute>

                {/*--------------------------- CONTACT --------------------------- */}
                <RestrictedLanguageRoute languages={ALL_LANGUAGES} path={`${url}/contact`}>
                    <SEOLinks languages={ALL_LANGUAGES} />
                    <ContactPage />
                </RestrictedLanguageRoute>

                {/*--------------------------- QR CODES --------------------------- */}
                <RestrictedLanguageRoute languages={ALL_LANGUAGES} path="/qr/:wineId/:vintageId/nutri">
                    <ConnectedQrcodeWinePage />
                </RestrictedLanguageRoute>

                {/* Redirect */}
                <Route path="/redirect/:wineId/:vintageId/nutri">
                    <RedirectPage nutri />
                </Route>
                <Route path="/redirect/:wineId/:vintageId">
                    <RedirectPage />
                </Route>

                <Route path="*">
                    <Page404 />
                </Route>
            </Switch>
            <FrontFooter />
        </>
    );
};

FrontRoutes.propTypes = {
    standalone: bool,
};

export default FrontRoutes;
