import { Box, makeStyles, Typography } from "@material-ui/core";
import PropTypes, { bool, string } from "prop-types";

import BaseButton from "../../../Components/Buttons/BaseButton";
import Tag from "../../../Components/Chips/Tag";
import IconSVG from "../../../Components/Icons/IconSVG";
import theme from "../../../globalTheme";
import Pricing from "../Pricing";
import BaseCheckbox from "Components/Inputs/BaseCheckbox";
import PlusChip from "Components/FrontBESV/PlusChip";

const useStyles = makeStyles(() => ({
    mainBox: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        margin: "0px",
        textAlign: "center",
        "& .MuiIcon-root": {
            fontSize: "50px",
            margin: "auto",
        },
    },
    title: {
        fontWeight: "bold",
    },
    classPrice: {
        ...theme.typography.h3,
        fontFamily: "Lato",
    },
}));

const ComparisonTableHeaderElement = (props) => {
    const styles = useStyles();
    return (
        <Box className={styles.mainBox}>
            <IconSVG path={props.path} style={{ height: "1.075em" }} />
            <Typography variant="h4" color="primary" className={styles.title}>
                {props.title}{" "}
                {props.title === "« Premium »" && props.discountPrice && (
                    <Tag
                        style={{
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.common.white,
                            padding: "8px 3px",
                            minHeight: "auto",
                            height: "auto",
                        }}
                        label={"- 50 %"}
                    />
                )}
            </Typography>
            {!props.disabledHeader ? (
                <>
                    <Pricing
                        noHeight={!props.switchCheck && "0"}
                        title={props.title}
                        price={props.price}
                        period={props.period}
                        classNamePrice={styles.classPrice}
                        totalMonthly={props.totalMonthly}
                        discountPrice={props.discountPrice}
                    />
                    <Box style={{ margin: "0.25em 0", alignSelf: "center", minHeight: 25 }}>
                        {props?.packPlus && (
                            <BaseCheckbox
                                style={{ textAlign: "center", zIndex: 0 }}
                                labelStyle={{ textAlign: "center" }}
                                checked={props.pack}
                                onChange={(e) => {
                                    props.setPack(e?.target?.checked);
                                }}
                                label={
                                    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <Typography variant="h6">
                                            Avec <b>pack</b>
                                        </Typography>
                                        <PlusChip style={{ marginLeft: 6 }} />
                                    </Box>
                                }
                            />
                        )}
                    </Box>
                    {!props?.hideCTA ? (
                        <BaseButton
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={props.onClick}
                            disabled={props.disabled}
                        >
                            {props.buttonLabel}
                        </BaseButton>
                    ) : null}
                </>
            ) : null}
        </Box>
    );
};

ComparisonTableHeaderElement.propTypes = {
    totalMonthly: PropTypes.any,
    price: PropTypes.any,
    pricePack: PropTypes.any,
    period: PropTypes.any,
    path: string,
    title: string,
    buttonLabel: string,
    onClick: PropTypes.func,
    disabled: bool,
    disabledHeader: bool,
    discountPrice: string,
    switchCheck: bool,
    hideCTA: bool,
    packPlus: bool,
    setPack: PropTypes.func,
    pack: bool,
};

export default ComparisonTableHeaderElement;
