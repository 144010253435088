/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import theme from "../../globalTheme";
import SimpleInput from "../Inputs/SimpleInput";
import PasswordInput from "../Inputs/PasswordInput";
import ConstraintsPasswordInput from "../Inputs/ConstraintsPasswordInput";
import BaseCheckbox from "../Inputs/BaseCheckbox";
import SelectInput from "../Inputs/SelectInput";
import TelephoneInput from "../Inputs/TelephoneInput";

const useStyles = makeStyles(() => ({
    title_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "2 / span 2",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 2",
        },
    },
    firstName_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "2 / span 5 ",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 6",
        },
    },
    lastName_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 6",
        },
    },
    email_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "2 / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 6",
        },
    },
    phone_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 6",
        },
    },
    password_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "2 / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 6",
        },
    },
    confirm_password_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 6",
        },
    },
    confidentiality_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
    globalLink: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontSize: theme.typography.subtitle1.fontSize,
    },
    link: {
        textDecoration: "underline",
    },
}));

//------------------------------------
// WINDOWS DIMENSIONS
const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
};
const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
};
//------------------------------------

const SubscriptionForm = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();

    return (
        <>
            {/* TITLE */}
            <Box className={styles.title_container}>
                <SelectInput
                    enumField={"SIMPLE_PERSON_SALUTATION"}
                    onChange={(e) => {
                        props.title.onChange(e);
                    }}
                    value={props.title.value}
                    label="Titre"
                    variant="outlined"
                    noClear
                />
            </Box>
            {width >= 600 && <Box className={styles.itemGrid}> {null} </Box>}
            {/* FIRSTNAME */}
            <Box className={styles.firstName_container}>
                <SimpleInput
                    value={props.firstName?.value}
                    onChange={props.firstName?.onChange}
                    correctValue={props.firstName?.correctValue}
                    style={{ minWidth: "100%" }}
                    label="Prénom"
                    required
                    error={props.firstName?.error ? "Le champ doit faire entre 1 et 30 caractères" : false}
                />
            </Box>
            {/* LASTNAME */}
            <Box className={styles.lastName_container}>
                <SimpleInput
                    label="Nom"
                    value={props.lastName?.value}
                    onChange={props.lastName?.onChange}
                    style={{ width: "100%" }}
                    required
                    correctValue={props.lastName?.correctValue}
                    error={props.lastName?.error ? "Le champ doit faire entre 2 et 30 caractères" : false}
                />
            </Box>
            {/* EMAIL */}
            <Box className={styles.email_container}>
                <SimpleInput
                    id="username"
                    name="username"
                    type="email"
                    autoComplete={"username"}
                    value={props.emailAddress?.value}
                    onChange={props.emailAddress?.onChange}
                    error={props.emailAddress?.error ? props.emailAddress?.clue : false}
                    correctValue={props.emailAddress?.correctValue}
                    style={{ width: "100%" }}
                    disabled={false}
                    label="Adresse e-mail"
                    required
                    onFocus={() => props.emailAddress?.onFocus()}
                />
            </Box>
            {/* PHONE */}
            <Box className={styles.phone_container}>
                <TelephoneInput
                    type="tel"
                    label="Téléphone"
                    required
                    flagValue={props.phoneNumberFlag.value}
                    flagOnChange={(e) => {
                        props.phoneNumberFlag.onChange(e);
                    }}
                    phoneValue={props.phoneNumber.value}
                    phoneOnChange={(e) => {
                        props.phoneNumber.onChange(e.target.value);
                    }}
                    error={props.phoneNumber?.error ? "Le numéro doit contenir 10 chiffres et commencer par 0" : false}
                    correctValue={props.phoneNumber?.correctValue}
                    maxLength={14}
                />
            </Box>
            {/* PASSWORD */}
            <Box className={styles.password_container}>
                <ConstraintsPasswordInput
                    id="password"
                    name="password"
                    value={props.password?.value}
                    onChange={props.password?.onChange}
                    error={props.password?.error}
                    focus={props.password?.focus}
                    setFocus={props.password?.setFocus}
                    constraints={props.password?.constraints}
                    style={{ width: "100%" }}
                    label="Mot de passe"
                    required
                />
            </Box>
            {/* PASSWORD CONFIRMATION */}
            <Box className={styles.confirm_password_container}>
                <PasswordInput
                    value={props.confirmationPassword?.value}
                    onChange={props.confirmationPassword?.onChange}
                    error={props.confirmationPassword?.error ? props.confirmationPassword?.helperText : false}
                    correctValue={props.confirmationPassword?.correctValue}
                    style={{ width: "100%" }}
                    label="Confirmation du mot de passe"
                    required
                />
            </Box>
            {/* NEWSLETTER */}
            <Box className={styles.confidentiality_container}>
                <BaseCheckbox
                    style={{ marginLeft: 0 }}
                    checked={props.newsletter?.value}
                    value={props.newsletter?.value}
                    onChange={props.newsletter?.onChange}
                    label="Je m’inscris à la newsletter qui m’informera de toutes les dernières actualités."
                />
            </Box>
        </>
    );
};

SubscriptionForm.propTypes = {
    setSubscriptionDatas: PropTypes.any,
    subscriptionDatas: PropTypes.any,
    control: PropTypes.any,
    register: PropTypes.any,
    title: PropTypes.shape({
        value: PropTypes.string,
        id: PropTypes.string,
        error: PropTypes.string,
    }),
    firstName: PropTypes.shape({
        id: PropTypes.string,
        error: PropTypes.string,
    }),
    lastName: PropTypes.shape({
        id: PropTypes.string,
        error: PropTypes.string,
    }),
    email: PropTypes.shape({ id: PropTypes.string, error: PropTypes.string }),
    subscriptionNewsletter: PropTypes.shape({
        id: PropTypes.string,
        error: PropTypes.string,
    }),

    telephone: PropTypes.shape({
        id: PropTypes.string,
        error: PropTypes.string,
    }),
    password: PropTypes.shape({
        id: PropTypes.string,
        error: PropTypes.string,
    }),
    passwordConfirmation: PropTypes.shape({
        id: PropTypes.string,
        error: PropTypes.string,
    }),
    conditions: PropTypes.shape({
        id: PropTypes.string,
        error: PropTypes.string,
    }),
};

export default SubscriptionForm;
